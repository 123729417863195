import { FileData } from "../FileData";

export class MessageBoxInbox {
  messageId: string;
  senderId: string;
  typeID: number;
  subject: string;
  sendingDate: Date;
  userTitle: string;
  student_F_name: string;
  student_L_name: string;
  userType: number;
  classCode: string;
  classNumber: number;
  hasRead: number;
  filesWereAttached: number;
  rowNumber: number;
  count: number;
  fromInbox: number;
  userImageToken: string;
}

export class MessageBoxOutbox {
  senderId: string;
  messageId: string;
  subject: string;
  sendingDate: Date;
  firstRecipientID: any;
  firstRecipientFirstName: string;
  firstRecipientLastName: string;
  recipientsList: string;
  recipientsCount: number;
  sentByMail: number;
  filesWereAttached: number;
  rowNumber: number;
  count: number;
  readMessage: Recipient[];
  unReadMessage: Recipient[];
  userImageToken:string;
  firstRecipientTypeId: number;

}

export class MessageBoxDraft {
  messageId: string;
  subject: string;
  createdOn: Date;
  filesWereAttached: number;
  recipientsCount: number;
  autoSendOn?: Date;
  isMunicipal?: number;
  rowNumber: number;
  count: number;
}

export class MessageBoxData {
  typeID: number;
  subject: string;
  messageContent: string;
  senderId: string;
  institutionCode: number;
  sendingDate: Date;
  attachedFile?: any;
  attachedFile2?: any;
  attachedFile3?: any;
  attachedFile4?: any;
  attachedFile5?: any;
  isVisible: boolean;
  signedOn?: any;
  sentByMail: number;
  replyDisabled?: boolean;
  parentAllowSign?: number;
  parentAllowSignDate?: Date;
  signingClasses?: any;
  filesWereAttached: number;
  hiddenRecipients: number;
  isMunicipal?: any;
  privateName: string;
  lastName: string;
  userType: number;
  recipientsName: string;
  totalRecipientsCount: number;
  roles: string;
  public Files: FileData[];
  public filesList: FileData[];
  messageAlertId :number;
  userImageToken: string;
  routeData:string;
  routePage:string;
}
export interface SignatureOption {
  title: string;
  value: number;
}
export interface SignatureForChildren {
  studentId: number;
  id: string;
  familyName: string;
  privateName: string;
  classCode: number;
  classNum: number;
}

export class SignatureDataPupil {
  note: string;
  classNumber: number;
  classCode: string;
  name: string;
  id: string;
  lastSign: string;
  optionId: number;
  isCanceled : boolean;
}


export interface Signature {
  signatureDataPupils: SignatureDataPupil[];
  message?: any;
  signDateOver: boolean;
  signLastDate?: any;
  allowSign: boolean;
  signatureSubType: string;
  signatureType: string;
  signatureToDate: Date;
  signatureFromDate: Date;
  signerPupilType: boolean;
  signerAdultPupil: boolean;
  signerIdToken: string;
  signerParentType: boolean;
  signatureForChildren?: SignatureForChildren[];
  signatureOptions: SignatureOption[];
  signedPupils: any[];
  alreadySigned: boolean;
  signDate?: any;
  lastSignDate?: any;
  signerId: number;
}

export class SingleMessageView {
  buttons: string[];
  messageData: MessageBoxData;
  signature: Signature;
  survey: Survey;
  roles: string;
}


export interface Answer {
  id: number;
  questionId: number;
  title: string;
  viewOrder: number;
  isSelected: number;
  count: number;
  total: number;
  key: string;
}

export class Question {
  id: number;
  title: string;
  originalQuestionId?: any;
  viewOrder: number;
  answers: Answer[];
  selectedAnswer: number;
  noAnswer: boolean;
}

export interface Survey {
  surveyFilled: boolean;
  surveyFilledDate: Date;
  questions: Question[];
  isVisible: boolean;
  showOrtClimate: boolean;
}

export class MessageItem {
  isActive: string;
  recipientsCount?: number;
  recipientsList: string;
  senderId: string;
  messageId: string;
  objects: Tile[];
  shortName: string;
  privateName: string;
  sendingDate: Date;
  lastName: string;
  fullName: string;
  fileAttached: number;
  subject: string;
  hasread = true;
  title: string;
  typeID: any;
  selected: boolean;
  fromInbox: number;
  userImageToken:string;
  userType:number;
  public Files: FileData[];

  public constructor(b: Partial<MessageItem> = {}) {
    Object.assign(this, b);
  }


}

export class Tile {
  class: string;
  color: string;
  cols: number;
  rows: number;
  text: string;
}

export class Folders {
  id: number;
  title: string;
  itemsCount: number;
}


export class DraftData {
  recipientsList: RecipientsList[];
  msgId: string;
  typeID: number;
  subject: string;
  messageContent: string;
  attachedFile?: any;
  attachedFile2?: any;
  attachedFile3?: any;
  attachedFile4?: any;
  attachedFile5?: any;
  autoSendOn?: any;
  ReplyDisabled ?: any;
  hiddenRecipients: number;
  public Files: FileData[];

}

export class RecipientsList {
  objectId: string;
  recipientId: string;
  title: string;
  institutionCode?: any;
  typeId: number;
  userType: string;
}

export class SearchAutoComplete {
  id: number;
  subject: string;
  sendingDate: Date;
  userTitle: string;
  student_F_name: string;
  student_L_name: string;
  userType: number;
  classCode: string;
  classNumber: number;
  messageType: string;
  type: string;

}
/* export class MessageFiles {
  public fileName: string;
  public fileUrl: string;
} */

export class Recipient {
  mailType: string;
  lastName: string;
  firstName: string;
  itemId: string;
  type: string;
  readDate: Date;
  title: string;
}

export class ReplyAndForwardMessage {
  public IsAllowdToReplay: boolean;
  public recipients: Recipient[];
  public Files: FileData[];
  public messageData: MessageBoxData;
}
