import { SharedService } from './_services/shared.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs/Subscription';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './_services/user-service.service';
import { IconsService } from './_services/icons';
import { Component, AfterContentChecked, ChangeDetectorRef, OnInit, Injectable, AfterViewChecked, OnDestroy, Inject, HostListener } from '@angular/core';
import { MobileService } from './_services/mobile-service.service';
import { LoginService } from './_services/login.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, take } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { RsaService } from './_services/Rsa.service';
import { isNumber } from 'underscore';

@Injectable({ providedIn: 'root' })
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  isLogin: boolean = false;
  showHeader: boolean = false;
  subscription: Subscription;

  constructor(
    private icons: IconsService,
    public mobileService: MobileService,
    public sharedService: SharedService,
    public loginSvc: LoginService,
    private router: Router,
    private ref: ChangeDetectorRef,
    public user: UserService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translate: TranslateService,
    private rsaService: RsaService,

    @Inject(DOCUMENT) private document: Document
  ) {

    icons.LoadAllIcons();
    const font = localStorage.getItem('fontSize');
    if (font != null) {
      let  defaultFontsize = this.translate.instant('defaultFontSize');
      if(!isNumber(defaultFontsize)) defaultFontsize = 10;
      const calculatedFontSize = (Number(defaultFontsize) * Number(font) / 100) * 100 / 16;
      document.getElementsByTagName('body').item(0).style.fontSize = calculatedFontSize + '%';
    }

    const accessibility = localStorage.getItem('accessibility');
    if (accessibility != null) {
      document.getElementsByTagName('body').item(0).classList.add(accessibility);
    }

    const fontaccessibility = localStorage.getItem('fontaccessibility');
    if (fontaccessibility != null) {
      fontaccessibility.trimEnd().split(" ").forEach(x => {
        document.getElementsByTagName('body').item(0).classList.add(x);
      })
    }

    const viewMode = localStorage.getItem('darkTheme')
    if (viewMode != null) {
      document.getElementsByTagName('body').item(0).classList.add(viewMode == '1'? 'dark-theme': 'dark-theme-2');
    }
    else document.getElementsByTagName('body').item(0).classList.add('light-theme');



    let lang = localStorage.getItem('language');
    if (lang === null) {
      lang = environment.defaultLanguage;
    }
    localStorage.setItem('language', lang);
    this.document.documentElement.lang = lang;
    setTimeout(() => {
      this.translate.use(lang);
    }, 100);
  }

  naviagteToLogin() {
    let stateUrl = this.rsaService.encrypt(location.pathname)
    this.router.navigate(['/account/login'], { queryParams: { 'stateUrl': stateUrl } });
  }
  ngOnInit() {
    if (this.loginSvc.pageName === 'forgotPassword'
      && location.pathname.toLowerCase().indexOf('/changepassword') === -1
      && location.pathname.toLowerCase().indexOf('/forgotpassword') === -1
      && location.pathname.toLowerCase().indexOf('/codeinput') === -1
      && location.pathname.toLowerCase().indexOf('/login') === -1
    ) {
      this.router.navigate(['/account/changePassword']);
      return;
    }

    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      this.isLogin = this.user.getUser() != null && this.router.url.toLowerCase() !== '/account/login' && this.router.url.toLowerCase() !== '/account/outerlogin';
      this.showHeader = this.user.getUser() == null && this.router.url.indexOf('/account') !== 0 && this.router.url.indexOf('/extrep') !== 0;
      const rt = this.getChild(this.activatedRoute);
      rt.data.pipe(take(1)).subscribe(data => {
        if (data.title) {
          this.titleService.setTitle('Webtop - ' + this.translate.instant(data.title));
        }
      });
    });
  }



  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }
}


