import { take } from 'rxjs/operators';
import { baseResult } from '../_models/baseResult';
import { PostService } from './postService';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(private postSrv: PostService) {

  }

  writeToLog(id, param1 = '', param2 = '', param3 = '', param4 = '') {
    this.postSrv.postData<boolean>('api/general/writeToLog', true,
      {
        id: id,
        param1: param1,
        param2: param2,
        param3: param3,
        param4: param4
      }).pipe(take(1))
      .subscribe((result: baseResult<boolean>) => { });
  }
}
