import { Component, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-lottie',
  templateUrl: './lottie.component.html',
  styleUrls: ['./lottie.component.css']
})
export class LottieComponent {

  @Input() set lottieName(value: string){
    this.optionsLottie = {
      ...this.optionsLottie, // In case you have other properties that you want to copy
      path: '../assets/lotties/'+value+'.json'
    };
  }

  optionsLottie: AnimationOptions;
  animation: any;
  
  handleAnimation(anim: any) {

    this.animation = anim;
  }

  clickedAnimation() {
    if (this.animation.isPaused)
      this.animation.play();
    else this.animation.pause();
  }


}
