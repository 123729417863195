import { MobileService } from 'src/app/_services/mobile-service.service';
import { UserImageComponent } from './../user-image/user-image.component';
import { RsaService } from 'src/app/_services/Rsa.service';
import { SharedService } from './../../_services/shared.service';
import { ConfirmDialogComponent } from './../../common/dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginUserNameData, UpdateUserProfile, UserProfile } from './../../_models/settings/settings';
import { PostService } from './../../_services/postService';
import { UserService } from 'src/app/_services/user-service.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { User } from 'src/app/_models/user';
import { take } from 'rxjs/operators';
import { baseResult } from 'src/app/_models/baseResult';
import { GenericMessage } from 'src/app/_models/sharedModels';
import { CookiesService } from 'src/app/_services/cookies-service.service';
import { SnackBarComponent } from 'src/app/common/snack-bar/snack-bar.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  @Input() viewName: string;
  @Output() isDirty = new EventEmitter<boolean>();
  loginMoe = this.cookieService.getItem('loginMoe');
  detailsForm: FormGroup = this.fb.group({
    email: [''],
    phonePrefix: ['050'],
    phone: [''],
    emailShownSchoolAlphone: [false],
    phoneShownSchoolAlphone: [false],
  });
  ChangePasswordTitle1: '';

  userName: FormGroup = this.fb.group({
    password: ['', [Validators.min(6)]],
    newUserName: [''],
  });

  password: FormGroup = this.fb.group({
    password: ['', Validators.min(8)],
    password1: ['', Validators.min(8)],
    password2: ['', [Validators.min(8), this.checkPasswords]],
  });

  phonePrefix: any[] = [
    { 'text': '050', 'value': '050' },
    { 'text': '051', 'value': '051' },
    { 'text': '052', 'value': '052' },
    { 'text': '053', 'value': '053' },
    { 'text': '054', 'value': '054' },
    { 'text': '055', 'value': '055' },
    { 'text': '056', 'value': '056' },
    { 'text': '057', 'value': '057' },
    { 'text': '058', 'value': '058' },
    { 'text': '059', 'value': '059' },
  ]
  isPhoneValidate: boolean = false;
  currentUser: User;
  data: UserProfile;
  userNameDetails: LoginUserNameData;
  smsCode: string = '';
  emailVerifySent: boolean = false;
  image: string;
  waitingimage: string;
  isStudent = true;
  constructor(
    private fb: FormBuilder,
    private user: UserService,
    private postSrv: PostService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private matDialog: MatDialog,
    private shared: SharedService,
    private cookieService: CookiesService,
    private rsa: RsaService,
    private mobileService: MobileService
  ) {
    this.currentUser = user.getUser();
    this.image = shared.getImageParam(this.currentUser.userId, this.currentUser.userImageToken,
      this.currentUser.institutionCode, false, user.getUser().userType);
    this.waitingimage = shared.getImageParam(this.currentUser.userId, this.currentUser.userImageToken,
      this.currentUser.institutionCode, true, user.getUser().userType) + '&ts=' + (new Date).getTime();
    this.isStudent = !(this.user.getUser().userType == 1 || this.user.getUser().userType == 2);
  }

  userNameChangedIn6Month(changedDate: Date): boolean {
    let d = new Date(changedDate);
    d = new Date(d.setMonth(d.getMonth() + 6));
    if (d.getTime() > new Date().getTime()) {
      this.userName.disable();
      return true;
    }
    return false;
  }

  passwordChangedIn24Hours(changedDate: Date): boolean {
    let d = new Date(changedDate);
    d = new Date(d.setHours(d.getHours() + 24));
    if (d.getTime() > new Date().getTime()) {
      this.password.disable();
      return true;
    }
    return false;
  }


  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const password = group.get('password1')?.value;
    const confirmPassword = group.get('password2')?.value;
    return password === confirmPassword ? null : { notSame: true }
  }

  verifyEmail() {
    this.postSrv.postData('api/settings/VerifyEmail',
      true,
      {}
    ).pipe(take(1)).subscribe((result: baseResult<boolean>) => {
      if (result.status) {
        if (result.data) {
          this.snackBar.openFromComponent(SnackBarComponent, {
            duration: 3000,
            data: { message: this.translate.instant('VerifyMessageSentToEmail'), type: 'succcess' }
          });
        }
      }
    });
  }

  getSMSVerifyCode() {
    const cellphone = this.data.cellphone;
    this.postSrv.postData('api/settings/GetSMSVerifyCode',
      true,
      {
        'param1': cellphone
      }
    ).pipe(take(1)).subscribe((result: baseResult<boolean>) => {
      if (result.status) {
        if (result.data) {
          this.viewName = 'smsCodeInput';
          this.snackBar.openFromComponent(SnackBarComponent, {
            duration: 3000,
            data: { message: this.translate.instant('VerifySMSSent'), type: 'succcess' }
          });
        }
      }
    });
  }

  onCodeChanged(code: string) {
    this.smsCode = code;
  }

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    this.smsCode = code;
    this.verifySMSCode();
  }

  verifySMSCode() {
    if (this.smsCode.length !== 6) { return; }
    const code: number = parseFloat(this.smsCode);
    this.postSrv.postData<GenericMessage>('api/settings/VerifyCellphoneCode',
      true,
      {
        'id': code
      }).pipe(take(1)).subscribe((result: baseResult<GenericMessage>) => {
        if (result.status) {
          if (result.data.message === 'Success') {
            this.viewName = 'personalDetails';
            this.data.isCellphoneVerified = true;
            this.snackBar.openFromComponent(SnackBarComponent, {
              duration: 3000,
              data: { message: this.translate.instant('CellphoneVerifiedSuccess'), type: 'succcess' }
            });
          } else {
            const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
              disableClose: true,
              data: {
                message: [this.translate.instant(result.data.message)],
                confirmBtn: this.translate.instant('OKBtn'), header: this.translate.instant('notification')
              },
              autoFocus: true
            });
          }
        }
      });
  }

  saveUserName() {
    if (this.data.loginUserName.hidePassword == false && this.userName.controls.password.value.length == 0) {
      this.snackBar.openFromComponent(SnackBarComponent, {
        duration: 3000,
        data: { message: this.translate.instant('AskForPassword'), type: 'warning' }
      });
      return false;
    }
    if (this.userName.controls.newUserName.value.length == 0) {
      this.snackBar.openFromComponent(SnackBarComponent, {
        duration: 3000,
        data: { message: this.translate.instant('AskForNewUserName'), type: 'warning' }
      });
      return false;
    }
    if (this.data.loginUserName.hidePassword == false && this.userName.controls.password.value == this.userName.controls.newUserName.value) {
      this.snackBar.openFromComponent(SnackBarComponent, {
        duration: 3000,
        data: { message: this.translate.instant('UserNameEqualPassword'), type: 'warning' }
      });
      return false;
    }
    this.postSrv.postData('api/settings/SaveUserName',
      true,
      {
        'password': this.userName.controls.password.value,
        'newUserName': this.userName.controls.newUserName.value
      }
    ).pipe(take(1)).subscribe((result: baseResult<GenericMessage>) => {
      if (result.status) {
        if (result.data.data) {
          this.data.loginUserName.userName = this.userName.controls.newUserName.value;
          this.data.loginUserName.lastChanged = result.data.data;
          this.snackBar.openFromComponent(SnackBarComponent, {
            duration: 3000,
            data: { message: this.translate.instant('UserNameUpdatedSuccess'), type: 'succcess' }
          });
        }
      }
    });
  }

  savePassword() {
    const pass = this.password.controls['password'].value.trim();
    const pass1 = this.password.controls['password1'].value.trim();
    const pass2 = this.password.controls['password2'].value.trim();
    if (this.shared.isEmpty(pass)) {
      this.snackBar.openFromComponent(SnackBarComponent, {
        duration: 3000,
        data: { message: this.translate.instant('enterExistPassword'), type: 'warning' }
      });
      return;
    }
    if (this.shared.isEmpty(pass1) || this.shared.isEmpty(pass2)) {
      this.snackBar.openFromComponent(SnackBarComponent, {
        duration: 3000,
        data: { message: this.translate.instant('AskForPassword'), type: 'warning' }
      });
      return;
    }
    if (pass === pass1) {
      this.snackBar.openFromComponent(SnackBarComponent, {
        duration: 3000,
        data: { message: this.translate.instant('NewPasswordEquelCurrent'), type: 'warning' }
      });
      return;
    }
    if (pass1 !== pass2) {
      this.snackBar.openFromComponent(SnackBarComponent, {
        duration: 3000,
        data: { message: this.translate.instant('PasswordsNotMatch'), type: 'warning' }
      });
      return;
    }
    if (this.shared.hasMoreThanAscii(pass1)) {
      this.snackBar.openFromComponent(SnackBarComponent, {
        duration: 3000,
        data: { message: this.translate.instant('HasMoreThanAscii'), type: 'warning' }
      });
      return;
    }
    this.postSrv.postData<GenericMessage>('api/user/ChangePassword',
      true,
      { 'Id': pass1, 'Param1': this.rsa.encryptStringToServer('CheckLastLogin'), 'Param2': pass })
      .pipe(take(1)).subscribe((result: baseResult<GenericMessage>) => {
        if (result.data) {
          if (result.data.message == 'PasswordChangeSuucess' || result.errorDescription == 'PasswordChangeSuucess') {
            this.data.passwordLastChange = new Date();
            this.snackBar.openFromComponent(SnackBarComponent, {
              duration: 3000,
              data: { message: this.translate.instant('PasswordChangeSuucess'), type: 'succcess' }
            });
          }
        } else {
          if (result.errorDescription == 'PasswordCanChangedOneTimeDaily') {
            this.snackBar.openFromComponent(SnackBarComponent, {
              duration: 3000,
              data: { message: this.translate.instant('PasswordCanChangedOneTimeDaily2'), type: 'warning' }
            });
          } else {
            this.snackBar.openFromComponent(SnackBarComponent, {
              duration: 3000,
              data: { message: this.translate.instant(result.errorDescription), type: 'warning' }
            });
          }
        }
      });
  }

  saveProfile() {
    switch (this.viewName) {
      case 'personalDetails':
        this.saveUserDetails();
        break;
      case 'userNameSettings':
        this.saveUserName();
        break;
      case 'passwordSettings':
        this.savePassword();
        break;
    }
  }

  loadUserProfile(): void {
    this.postSrv.postData('api/settings/GetUserProfile',
      true,
      {}
    ).pipe(take(1)).subscribe((result: baseResult<UserProfile>) => {
      if (result.status) {
        this.data = result.data;
        this.detailsForm.controls.email.setValue(result.data.email);
        if (result.data.cellphone != null) {
          this.detailsForm.controls.phonePrefix.setValue(result.data.cellphone.slice(0, 3));
          this.detailsForm.controls.phone.setValue(result.data.cellphone.slice(3, 10));
        }
        this.detailsForm.controls.emailShownSchoolAlphone.setValue(result.data.showMyEmail);
        this.detailsForm.controls.phoneShownSchoolAlphone.setValue(result.data.showMyCellphone);
      }
    });
  }

  saveUserDetails() {
    const email = this.detailsForm.controls.email.value;
    if (!this.shared.isEmpty(email) && !this.shared.validateEmail(email)) {
      this.snackBar.openFromComponent(SnackBarComponent, {
        duration: 3000,
        data: { message: this.translate.instant('updateNotificationFailedInsertValidEmail'), type: 'warning' }
      });
      return false;
    }
    const cellphone = this.detailsForm.controls.phone.value;
    if (!this.shared.isEmpty(cellphone) && cellphone.length == 7 && !/^\d+$/.test(cellphone)) {
      this.snackBar.openFromComponent(SnackBarComponent, {
        duration: 3000,
        data: { message: this.translate.instant('InsertValidCellphone'), type: 'warning' }
      });
      return false;
    }
    this.postSrv.postData('api/settings/SaveUserProfile',
      true,
      {
        'email': email,
        'cellphone': this.detailsForm.controls.phonePrefix.value + cellphone,
        'showMyEmail': this.detailsForm.controls.emailShownSchoolAlphone.value,
        'showMyCellphone': this.detailsForm.controls.phoneShownSchoolAlphone.value
      }
    ).pipe(take(1)).subscribe((result: baseResult<UpdateUserProfile>) => {
      if (result.status) {
        if (result.data.emailVerify) {
          this.emailVerifySent = result.data.emailVerify;
          this.data.isEmailVerified = true;
          this.data.email = email;
        }
        if (result.data.cellphoneVerify) {
          this.data.isCellphoneVerified = false;
          this.data.cellphone = this.detailsForm.controls.phonePrefix.value + cellphone;
          this.viewName = 'smsCodeInput'
        }
        this.snackBar.openFromComponent(SnackBarComponent, {
          duration: 3000,
          data: { message: this.translate.instant('PersonalDetailsSavedSuccess'), type: 'succcess' }
        });
      }
    });
  }

  ngOnInit() {
    if (this.user.getUser().isTeacher) {
      this.ChangePasswordTitle1 = this.translate.instant('ChangePasswordTitle1');
    } else {
      this.ChangePasswordTitle1 = this.translate.instant('ChangePasswordTitle1').replace('8', '6');
    }
    this.detailsForm.valueChanges.subscribe(e => this.isDirty.emit(true));
    this.userName.valueChanges.subscribe(e => this.isDirty.emit(true));
    this.password.valueChanges.subscribe(e => this.isDirty.emit(true));
    this.loadUserProfile();
  }

  openImageCrop() {
    const dialogRef = this.matDialog.open(UserImageComponent, {
      disableClose: false,
      autoFocus: true,
      width: '500px',
      maxWidth: '95vw',
      data: {
        watingImage: !this.isStudent ? '' : this.shared.getImageParam(this.currentUser.userId, this.currentUser.userImageToken, this.user.getUser().institutionCode, true, this.user.getUser().userType),
        image: this.shared.getImageParam(this.currentUser.userId, this.currentUser.userImageToken, this.user.getUser().institutionCode, false, this.user.getUser().userType),
        checkWatingImage: this.isStudent
      },
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe((result: any) => {
      setTimeout(() => {
        if (!this.isStudent) {
          this.image = this.shared.getImageParam(this.currentUser.userId, this.currentUser.userImageToken, this.currentUser.institutionCode, true, this.user.getUser().userType) + '&ts=' + (new Date).getTime();
        } else {
          this.waitingimage = this.shared.getImageParam(this.currentUser.userId, this.currentUser.userImageToken, this.currentUser.institutionCode, true, this.user.getUser().userType) + '&ts=' + (new Date).getTime();
        }
      }, 500);

    });

  }

}
