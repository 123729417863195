import { UntypedFormControl } from '@angular/forms';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.css']
})
export class SwitchComponent implements OnInit {

  constructor() { }

  @Input() checked: boolean = false;
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() disabled: boolean = false;
  @Input() text: string;
  @Input() title: string;


  @Output() onValueChange = new EventEmitter<boolean>();

  valueChanged(val: boolean){
    this.control.setValue(val)
    this.onValueChange.emit(val);
  }

  ngOnInit() {
  }

}
