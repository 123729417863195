import { MobileService } from './../_services/mobile-service.service';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild, ElementRef, ViewChildren, Injectable, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocationStrategy } from '@angular/common';
import { UserService } from '../_services/user-service.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  length = 0;
  currentElment = 0;
  title: string = "Settings_Title";
  domEles: any;
  @ViewChildren('menuItems') items: ElementRef;
  constructor(
    @Inject(MAT_DIALOG_DATA) data: { selectedItem: number },
    public mobileService: MobileService,
    private location: LocationStrategy,
    private user: UserService
  ) {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      ///history.pushState(null, null, window.location.href);
    });
    this.settingsMenu.find(x => x.title == 'View').items.push('ViewMode');
    if (data.selectedItem != null) {
      this.activeView = this.settingsMenu[0].items[data.selectedItem];
    }
  }


  showContent = false;
  settingsMenu: any[] = [
    {
      'title': 'PersonalProfile',
      'items': ['personalDetails', 'userNameSettings', 'passwordSettings'],
      'showData': false
    },
    {
      'title': 'View',
      'items': ['ApplicationLanguage', 'DateCalender', 'accessibility'],
      'showData': true
    },
    {
      'title': 'notification_Title',
      'items': ['PushNotification', 'EmailNotification'],
      'showData': false
    },
  ];
  activeView: string = this.settingsMenu[0].items[0];
  activeCategory: string = this.settingsMenu[0].title;
  isDirty = false;
  ngOnInit() {

    setTimeout(() => {
      document.getElementById('row_0').focus();
    }, 300);
  }

  changeFocus(x) {
    this.currentElment = this.currentElment + x;
    if (this.currentElment < 0) {
      this.currentElment = (this.items as any)._results.length - 1;
    }
    if (this.currentElment > (this.items as any)._results.length - 1) {
      this.currentElment = 0;
    }
    (this.items as any)._results[this.currentElment].nativeElement.focus();
  }

}
