// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-wrapper {
  padding: 16px;
  font-family: var(--regularFont);
  font-weight: normal;
  position: relative;
  margin: auto;
}

canvas {
  width: 100%;
  height: 100%;
  margin: auto;
  font-size: var(--font-size--default);
}

.line-through {
  text-decoration: line-through;
}

.chartjs-color-box {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: var(--rightRtl_8);
  margin-right: var(--leftRtl_8Null);
  border: 1px solid var(--white) !important;
  border-radius: 5px;
}

.custom-legend-item {
  font-family: var(--regularFont);
  font-weight: normal;
  font-size: var(--font-size--default);
  line-height: 2;
  cursor: pointer;
  padding-right: var(--leftRtl_16);
  padding-left: var(--rightRtl_16Null);
}

canvas {
  max-width: 100%;
}

.custom-legend {
  text-align: center;
  flex-direction: column;
}

@media screen and (min-width: 960px) {
  .chart-wrapper {
    max-height: 350px;
    max-width: 350px;
  }
}

@media screen and (max-width: 959px) {
  canvas {
    max-height: 240px;
    max-width: 240px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/common/charts/dought-chart/dought-chart.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+BAA+B;EAC/B,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,oCAAoC;AACtC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,kCAAkC;EAClC,yCAAyC;EACzC,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;EAC/B,mBAAmB;EACnB,oCAAoC;EACpC,cAAc;EACd,eAAe;EACf,gCAAgC;EAChC,oCAAoC;AACtC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE;IACE,iBAAiB;IACjB,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,iBAAiB;IACjB,gBAAgB;EAClB;AACF","sourcesContent":[".chart-wrapper {\r\n  padding: 16px;\r\n  font-family: var(--regularFont);\r\n  font-weight: normal;\r\n  position: relative;\r\n  margin: auto;\r\n}\r\n\r\ncanvas {\r\n  width: 100%;\r\n  height: 100%;\r\n  margin: auto;\r\n  font-size: var(--font-size--default);\r\n}\r\n\r\n.line-through {\r\n  text-decoration: line-through;\r\n}\r\n\r\n.chartjs-color-box {\r\n  display: inline-block;\r\n  width: 10px;\r\n  height: 10px;\r\n  margin-left: var(--rightRtl_8);\r\n  margin-right: var(--leftRtl_8Null);\r\n  border: 1px solid var(--white) !important;\r\n  border-radius: 5px;\r\n}\r\n\r\n.custom-legend-item {\r\n  font-family: var(--regularFont);\r\n  font-weight: normal;\r\n  font-size: var(--font-size--default);\r\n  line-height: 2;\r\n  cursor: pointer;\r\n  padding-right: var(--leftRtl_16);\r\n  padding-left: var(--rightRtl_16Null);\r\n}\r\n\r\ncanvas {\r\n  max-width: 100%;\r\n}\r\n\r\n.custom-legend {\r\n  text-align: center;\r\n  flex-direction: column;\r\n}\r\n\r\n@media screen and (min-width: 960px) {\r\n  .chart-wrapper {\r\n    max-height: 350px;\r\n    max-width: 350px;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 959px) {\r\n  canvas {\r\n    max-height: 240px;\r\n    max-width: 240px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
