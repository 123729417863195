import { UserService } from 'src/app/_services/user-service.service';
import { SharedService } from 'src/app/_services/shared.service';
import { Lesson } from './../../../_models/pupilCard/PupilCardScheduale';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { SortItem } from '../../../_models/sortItem';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { GetUserViaSearches, teacheresView } from '../../../_models/messageBox/Recipients';
import { baseResult } from '../../../_models/baseResult';
import { PostService } from '../../../_services/postService';
import { startWith, map, debounceTime, take } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../../../_services/commonService';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChipItem } from 'src/app/_models/chip';
import { StudentTeachersDetailsComponent } from 'src/app/message-box/new-message/student-teachers-details/student-teachers-details.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarComponent } from '../../snack-bar/snack-bar.component';

@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['../inputs.css', './chip-list.component.css']
})
export class ChipListComponent implements OnInit {

  @Input() searchList: GetUserViaSearches[];
  @Input() label = '';
  @Input() addTitle = true;
  @Input() items: ChipItem[] = [];
  @Input() removable = true;
  @Input() allowToAdd = true;
  @Input() showParentName = true;
  @Input() selectable = false;
  @Input() allowToAddLabel = '';
  @Input() userType = '';
  @Input() openGroupMembers = true;
  @Input() disabled: boolean = false;
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Output() addItem = new EventEmitter<any>();
  @Output() removeItem = new EventEmitter<string>();
  enterLoop = true;
  group = new UntypedFormGroup({
    searchUserCtrl: new UntypedFormControl('',),
  });

  @ViewChild('searchUserInput') searchUserInput: ElementRef<HTMLInputElement>;
  filteredsearchUsers: Observable<GetUserViaSearches[]>;
  allsearchUsers: GetUserViaSearches[] = [];
  readonly separatorKeysCodes: number[] = [ENTER];
  constructor(
    private postSrv: PostService,
    private commonSrv: CommonService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public sharedService: SharedService,
    public user: UserService
  ) {
    this.filteredsearchUsers = this.searchUserCtrl.valueChanges.pipe(
      startWith(<string>null),
      debounceTime(300),
      map((searchUser: GetUserViaSearches | null) => searchUser ? this._filter(searchUser, this.getSearchIndex()) : this.tmp(searchUser)));
  }
  tmp(searchUser): any[] {
    return [];
  }

  getSearchIndex() {
    let tmp = (new Date()).getTime();
    this.lastSearch = tmp;
    return tmp;

  }

  get searchUserCtrl() { return this.group.get('searchUserCtrl'); }

  ngOnInit() {
    if (this.disabled)
      this.control.disable();
  }
  resumeSearch() {
    this.enterLoop = true;
  }
  removeEnterItem() {
    const tmp = this.items[this.items.length - 1] as ChipItem;
    if (tmp.lastName === tmp.value) {
      this.items.pop();
      this.searchUserCtrl.setValue('');
      this.snackBar.openFromComponent(SnackBarComponent, {
        duration: 3000,
        data: { message: this.translate.instant('selectFromList'), type: 'warning' }
      });
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.items.push(new ChipItem(value.trim(), '', value.trim(), '', ''));
    }
    if (input) {
      input.value = '';
    }
  }

  remove(item: ChipItem): void {
    const index = this.items.indexOf(item);
    if (index >= 0) {
      this.items.splice(index, 1);
    }
    this.removeItem.emit(item.value);
  }
  clearText() {
    this.searchUserInput.nativeElement.value = '';

  }

  openGroupMembers_(item: ChipItem) {

    if (!this.openGroupMembers || item.type == '')
      return;
    this.postSrv.postData<teacheresView[]>('api/messageBox/RecipientsInGroup',
      true,
      {
        "recipientId": item.value,
        "recipientType": item.type
      }).pipe(take(1))
      .subscribe((result: baseResult<teacheresView[]>) => {
        this.dialog.open(StudentTeachersDetailsComponent, {
          data: {
            title: item.lastName,
            teachers: result.data
          }
        });
      });
  }

  selected(event: MatAutocompleteSelectedEvent): void {

    if (this.items.filter(x => x.value.itemId === event.option.value.itemId || x.value === event.option.value.itemId).length > 0) {
      this.snackBar.openFromComponent(SnackBarComponent, {
        duration: 3000,
        data: { message: this.translate.instant('DuplicateMember'), type: 'warning' }
      });
      this.searchUserInput.nativeElement.value = '';
      return;
    }
    this.items.push(new ChipItem(
      event.option.value.lastName,
      event.option.value.firstName,
      event.option.value.itemId,
      this.addTitle ? this.translate.instant(event.option.value.type) : '',
      '',
      event.option.value.userImageToken,
      event.option.value.avatarId
    ));
    this.searchUserInput.nativeElement.value = '';
    this.group.controls['searchUserCtrl'].setValue(null);
    this.addItem.emit(event.option.value);
    // this.updateValidator();
  }
  resultAsk = 0;
  resultAskArr = []
  lastSearch = 0;
  _filter(value: any, searchIndex): GetUserViaSearches[] {
    this.resultAsk = (new Date()).getTime();
    this.resultAskArr.push(this.resultAsk);
    if (!this.enterLoop) {
      return this.allsearchUsers;
    } else if (this.searchList !== undefined && this.searchList !== null && value.length > 0) {
      return this.searchList.filter(x => x.firstName.includes(value) || x.lastName.includes(value)
        || x.firstName.includes(this.sharedService.replcaeLanguage(value))
        || x.lastName.includes(this.sharedService.replcaeLanguage(value)))
    } else if (value.length > 0) {
      this.postSrv.postData<GetUserViaSearches[]>('api/messageBox/RecipientsAutoComplete',
        true,
        {
          'SearchQuery': value,
          'IsManual': false,
          'UserType': this.userType,
          'searchParentName': this.showParentName
        }).pipe(take(1))
        .subscribe((result: baseResult<GetUserViaSearches[]>) => {
          if (searchIndex < this.lastSearch) {
            return;
          }

          if (this.resultAsk != this.resultAskArr.slice(-1)[0]) { // show only the last result
            return;
          }
          if (result.data === null || result.data.length === 0) {
            this.allsearchUsers = [];
            if (this.enterLoop) {
              this.enterLoop = false;
              this.searchUserCtrl.setValue(this.searchUserCtrl.value);
            }
            return this.allsearchUsers;
          } else {
            this.allsearchUsers = result.data;
            if (this.enterLoop) {
              this.enterLoop = false;
              this.searchUserCtrl.setValue(this.searchUserCtrl.value);
            }
            return this.allsearchUsers;
          }
        });
    }

    return this.allsearchUsers;
  }

  getUserType(item) {
    if (item.type == 'parent') return 2;
    if (item.type == 'teacher') return 1;
    if(item.extraText == this.translate.instant('UserType1')) return 1;
    if(item.extraText == this.translate.instant('UserType2')) return 2;
    return 0;
  }

}
