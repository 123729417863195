import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  Output,
} from '@angular/core';
import { fromEvent, merge, of, Subscription, timer } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[long-press]',
})
export class LongPressDirective {
  @Output()
  onLongPress = new EventEmitter();

  private _timeout: any;

  @HostListener('touchstart') onTouchStart(e) {
    this._timeout = setTimeout(() => {
      this.onLongPress.emit(e);
    }, 500);
  }

  @HostListener('touchend') onTouchEnd() {
    clearTimeout(this._timeout);
  }

  @HostListener('touchmove') onTouchMove() {
    clearTimeout(this._timeout);
  }
}
