import { UserService } from 'src/app/_services/user-service.service';
import { Router } from '@angular/router';
import { SharedService } from './../../_services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageItem } from './../../_models/messageBox/MessageBox';
import { Component, ElementRef, Input, OnInit, ViewChild, AfterViewInit, ViewChildren, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-unread-messages-preview',
  templateUrl: './unread-messages-preview.component.html',
  styleUrls: ['./unread-messages-preview.component.scss']
})
export class UnreadMessagesPreviewComponent implements OnInit {

  @Input() messages: MessageItem[];
  @ViewChildren('item') items: ElementRef;c
  @Output() messageClicked = new EventEmitter();
  currentElment = 0;
  constructor(
    private translate: TranslateService,
    public shared: SharedService,
    private router: Router,
    public user: UserService
  ) { }

  today: Date = new Date();
  yesterday: Date = new Date(this.today.setDate(this.today.getDate() - 1));

  openMessage(id: any): void {
    this.messageClicked.emit();
    this.shared.setMsgId(id);
    this.router.navigate(['Messages/LoadUnread'], { queryParams: { msgId: id }});
  }
  closeMenu() {
    this.messageClicked.emit();
  }
  menuOpened() {
    setTimeout(() => {
      (this.items as any)._results[0]?.nativeElement.focus();
    }, 0);
  }
  changeFocus(x) {
    this.currentElment = this.currentElment + x;
    if (this.currentElment < 0 ) {
      this.currentElment = this.messages.length - 1;
    }
    if (this.currentElment > this.messages.length - 1  ) {
      this.currentElment = 0;
    }
    (this.items as any)._results[ this.currentElment].nativeElement.focus();
  }

  ngOnInit() {
  }

}
