import { SharedService } from 'src/app/_services/shared.service';
import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-details-dialog',
  templateUrl: './details-dialog.component.html',
  styleUrls: ['./details-dialog.component.css']
})
export class DetailsDialogComponent implements OnInit {

  @Input() transformOriginClass: string;
  @Input() title: string;
  @Input() titleColor: string;
  @Input() showBack = false;
  @Input() showFooter = false;
  @Input() showBigFooter = false;
  @Input() footerButtonText = '';
  @Input() disabledButton = false;
  @Input() dialogContentClass = '';
  @Output() onBackClick = new EventEmitter<boolean>();
  @Output() onCloseDialog = new EventEmitter<boolean>();
  @Output() submitFunction = new EventEmitter();


  constructor(
    private dialogRef: MatDialogRef<DetailsDialogComponent>,
    public shared:SharedService
  ) {
    this.dialogRef.addPanelClass('details-modal');
  }
  closeDialog() {
    if(this.onCloseDialog.observers.length > 0) {
      this.onCloseDialog.emit();
    } else {
      this.dialogRef.close(false);
    }
  }

  backClick(){
    this.onBackClick.emit(true);
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.submitFunction.emit();
  }

}
