import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  private scrollPositionSubject = new BehaviorSubject<number>(0);
  private scrollStoppedSubject = new BehaviorSubject<boolean>(false);
  private scrollDownSubject = new BehaviorSubject<boolean>(false);
  scrollPosition = this.scrollPositionSubject.asObservable();
  scrollStopped = this.scrollStoppedSubject.asObservable();
  scrollDown = this.scrollDownSubject.asObservable();

  updateScrollPosition(scrollPosition: number) {
    this.scrollPositionSubject.next(scrollPosition);
  }

  updateScrollStopped(scrollStopped: boolean) {
    this.scrollStoppedSubject.next(scrollStopped);
  }

  updateScrollDown(value: boolean){
    this.scrollDownSubject.next(value)
  }
}