
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MobileService } from 'src/app/_services/mobile-service.service';

@Component({
  selector: 'app-alert-info',
  templateUrl: './alert-info.component.html',
  styleUrls: ['./alert-info.component.css']
})
export class AlertInfoComponent implements OnInit,AfterViewInit {

  @Input() cssClass = '';
  @Input() isOuter: boolean = false;

  hasOverFlow: boolean;
  showMore: boolean = false;
  @ViewChild('content', { read: ElementRef, static: false }) content: ElementRef;

  constructor(
    private mobileService:MobileService
  ) { }

  ngAfterViewInit(): void {
   this.checkContent();
  }

  checkContent() {
    if (this.content == undefined || !this.mobileService.isMobileView) {
      return;
    }
    if (this.content.nativeElement.offsetHeight < this.content.nativeElement.scrollHeight ||
      this.content.nativeElement.offsetWidth < this.content.nativeElement.scrollWidth) {
      this.hasOverFlow = true;
      //this.showMore = true;
    } else {
      this.showMore =false;
      this.hasOverFlow = false;
    }
  }

  ngOnInit(): void {
  }

}

