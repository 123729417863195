export const environment = {
  production: true,
  apiUrl: 'https://testserver.smartschool.co.il/server',
  reportUrl: 'https://testserver.smartschool.co.il/serverReports/',
  ClientCaptchaKey: '6Lc3FToeAAAAAEx1PNfEgSvIfrH1jl20NcigjolX',
/*   ClientCaptchaKeyInvisible: '6LdFnyAqAAAAANKCeiZMBYFeN7SUjJkb-LqtQwdh', */
  ClientCaptchaKeyInvisible: '6Lf-Bz4qAAAAAClftyz9ZpD7TJ93bQ15wpoiuLLJ',

  loginMinstry: 'https://www.webtop.co.il/applications/loginMOENew/default.aspx',
  currentYear: 2025,
  defaultLanguage: 'he',
  numBackYears: 10,
  viewSmartModulesFromYear:2009,
  timeStamp: '1670936612224',
  studtentImage: '*',
  AllowedFileTypes: "gif,png,jpg,jpeg,bmp,tif,tiff,mp3,wma,wav,ogg,flac,wmv,mswmm,avi,mp4,mpg,mkv,mov,flv,swf,pdf,doc,docx,dot,dotx,ppt,pptx,xls,accdb,xlsx,pps,ppsx,rtf,txt,flipchart,ink,notebook,pub,xps,ggb,accdb,csv,sketchup,sb,dwg,pkt"
};
