import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/_services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { formattedDate } from './../../../_pipes/formattedDate';
import { Component, EventEmitter, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { HebrewDatePipe } from 'src/app/_pipes/hebrewDate.pipe';
import { DatepickerHeader } from './datepicker-header/datepicker-header.component';
import { UserService } from 'src/app/_services/user-service.service';
import { take } from 'rxjs-compat/operator/take';
import { MobileService } from 'src/app/_services/mobile-service.service';

@Component({
  selector: 'app-calender-picker',
  templateUrl: './calender-picker.component.html',
  styleUrls: ['./calender-picker.component.css', '../inputs.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: localStorage.getItem('language') != null ? localStorage.getItem('language') : environment.defaultLanguage },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class CalenderPickerComponent implements OnInit {

  @Input() year: number = environment.currentYear;
  @Input() svgOnly = false;
  @Input() public compTabIndex = 0;
  @Input() label?: string;
  private _control?: FormControl = new FormControl(new Date());
  @Input() set control(value: FormControl) {

    this.startViewDate = value.value != null ? new Date(value.value) : new Date();
    this._control = value;
    this.setShowDateValue(value.value);
  }
  get control(): FormControl {
    return this._control;
  }


  @Input() public disabled = false;
  @Input() public showAllDays = false;
  @Input() startView = 'month';
  @Input() outerActivateDate: Date = null;
  @Output() selectDate = new EventEmitter<Date>();


  @Input() startDate: Date = new Date();
  @Input() endDate: Date = new Date();
  startViewDate: Date = new Date();
  isActive: boolean = false;

  constructor(
    private renderer: Renderer2,
    private hebDate: HebrewDatePipe,
    private formattedDateSrv: formattedDate,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private shared: SharedService,
    private mobileSrv: MobileService
  ) {
    this.isHebrewDate = this.shared.isHebrewDate();
  }

  customHeader = DatepickerHeader;
  activeDate: Date = new Date();
  currentView: string;
  showDate = '';
  isHebrewDate: boolean;

  studyYearRange = (d: Moment): boolean => {
    if (this.showAllDays) {
      return true;
    }
    let date = new Date();
    if (d != null) {
      date = d.toDate();
    }
    return this.startDate <= date && this.endDate >= date;
  }

  onDateChange(value): void {
    if (!this.svgOnly) {
      this.setShowDateValue(value._d);
    }
    this.selectDate.emit(value._d);

  }

  monthpickerOpened(event: any) {
    if (event !== 'year') {
      return;
    }
    this.currentView = 'year';
    if (!this.isHebrewDate) {
      return;
    }
    setTimeout(() => {
      this.gethebMonthDate();
    });
  }

  pickerOpened(event: any, calender: MatDatepicker<any>) {
    if (event === null || event === undefined) {
      if (this.outerActivateDate !== null) {
        this.activeDate = new Date(this.outerActivateDate);
      }
      else this.activeDate = new Date(this.control.value);
      this.control.setValue(this.activeDate);
      this.currentView = this.startView;
    } else {
      this.activeDate = event._d;
      if (this.startView === 'year') {
        this.control.setValue(this.activeDate);
        if (!this.svgOnly) {
          this.setShowDateValue(event._d);
        }
        this.selectDate.emit(this.activeDate);
        calender.close();
        setTimeout(() => {
          document.getElementById('datePickerInput').focus();
        }, 100);
        return;
      }
      this.currentView = 'month';
    }
    if (!this.isHebrewDate) {
      return;
    }
    setTimeout(() => {
      if (event == null) {
        const buttons = document.querySelectorAll('mat-calendar .calender-arrows');
        buttons.forEach(btn => {
          if (btn.classList.contains('prev')) {
            this.renderer.listen(btn, 'click', (event) => {
              setTimeout(() => {
                if (this.currentView === 'month') {
                  this.activeDate.setMonth(this.activeDate.getMonth() - 1);
                  this.getHebDate();
                } else {
                  this.activeDate.setFullYear(this.activeDate.getFullYear() - 1);
                  this.gethebMonthDate();
                }
              });
            });
          } else {
            this.renderer.listen(btn, 'click', (event) => {
              setTimeout(() => {
                if (this.currentView === 'month') {
                  this.activeDate.setMonth(this.activeDate.getMonth() + 1);
                  this.getHebDate();
                } else {
                  this.activeDate.setMonth(this.activeDate.getFullYear() + 1);
                  this.gethebMonthDate();
                }
              });
            });
          }
        });
      }
      if (this.currentView === 'month') {
        this.getHebDate();
      } else { this.gethebMonthDate(); }
    });
  }
  checkDate(selectedDate: Date, calender: MatDatepicker<any>): boolean {
    return selectedDate >= calender._getMinDate() && selectedDate <= calender._getMaxDate();
  }

  gethebMonthDate() {
    const elems = document.querySelectorAll('mat-calendar .mat-calendar-body-cell-content');
    let currentMonth = 0;
    elems.forEach(y => {
      y.classList.add('heb-date');
      y.classList.add('flex');
      y.classList.add('column');
      const startHebDate = this.hebDate.transform(new Date(this.activeDate.getFullYear(), currentMonth, 1)).split(' ');
      this.checkIfHas2Adar(startHebDate);
      const endHebDate = this.hebDate.transform(new Date(this.activeDate.getFullYear(), currentMonth + 1, 0)).split(' ');
      this.checkIfHas2Adar(endHebDate);
      y.innerHTML = `<div class="regular">${y.innerHTML}</div><div class="hebrew">${startHebDate[1]} - ${endHebDate[1]}</div>`;
      currentMonth += 1;
    });
  }

  getHebDate() {
    const elems = document.querySelectorAll('mat-calendar .mat-calendar-body-cell-content');
    let day = 1;
    elems.forEach(y => {
      y.classList.add('heb-date');
      y.classList.add('flex');
      y.classList.add('column');
      const currentDate = new Date(this.activeDate.getFullYear(), this.activeDate.getMonth(), day);
      const hebDate = this.hebDate.transform(currentDate).split(' ');
      this.checkIfHas2Adar(hebDate);
      let hebrewText;
      if (hebDate[0] === 'א׳') {
        hebrewText = `<div class="hebrew bold-text">${hebDate[1]}</div>`;
      } else { hebrewText = `<div class="hebrew">${hebDate[0]}</div>`; }
      y.innerHTML = `<div class="regular">${y.innerHTML}</div>${hebrewText}`;
      day += 1;
    });
  }

  checkIfHas2Adar(hebDate: string[]) {
    if (hebDate.length === 4) {
      hebDate[1] += ' ' + hebDate[2];
      hebDate[2] = hebDate[3];
    }
  }

  setShowDateValue(value) {
    if (this.shared.isEmpty(value)) {
      this.showDate = '';
    } else {
      if (this.startView === 'month') {
        this.showDate = this.formattedDateSrv.transform(value);
        if (this.isHebrewDate) {
          this.showDate += `, ${this.hebDate.transform(value)}`;
        }
      } else {
        this.showDate = this.datePipe.transform(value, this.translate.instant('monthDateFormat'));
        if (this.isHebrewDate) {
          const hebDate = this.hebDate.transform(value).split(' ');
          this.checkIfHas2Adar(hebDate);
          this.showDate += `, ${hebDate[1]} ${hebDate[2]}`;
        }
      }
    }
  }

  ngOnInit() {
    if (!this.svgOnly) {
      this.setDisabled(this.disabled);
    }
    this.startDate = new Date(localStorage.getItem("startDate"));
    this.endDate = new Date(localStorage.getItem("endDate"));
    if (this.year != environment.currentYear){
      const year = new Date().getFullYear() - (environment.currentYear - this.year);
      this.activeDate.setFullYear(year);
    }
  }




  setDisabled(flag) {
    if (flag) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }


  checkDisabled() {
    return this.control?.disabled ? ' mat-form-field-disabled' : '';
  }

}
