import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent implements OnInit {

  constructor() { }

  @Input() title:string;
  @Input() expanded: boolean = false;
  @Input() panelOpenState: boolean = false;
  @Input() hideToggle: boolean = false
  @Input() level: number = 2;
  @Input() compTabIndex: number = 0;


  ngOnInit() {
  }

}
