import { Component, HostListener, OnInit } from '@angular/core';
import { ScrollService } from 'src/app/_services/scroll-service.service';

@Component({
  selector: 'app-fab-button-container',
  templateUrl: './fab-button-container.component.html',
  styleUrls: ['./fab-button-container.component.css']
})
export class FabButtonContainerComponent implements OnInit {
  isMiniFabHidden = false;
  scrollTimer: any;

  constructor(private scrollService: ScrollService) { }

  ngOnInit() {

    this.scrollService.scrollDown.subscribe(value => {
      if (value) {
        this.isMiniFabHidden = true;
        clearTimeout(this.scrollTimer);
        this.scrollTimer = setTimeout(() => {
          this.isMiniFabHidden = false;
        }, 1000); // Adjust the delay time as needed
      }
    });

    this.scrollService.scrollStopped.subscribe(scrollStopped => {
      if (scrollStopped) {
        setTimeout(() => {this.isMiniFabHidden = false;},1000);
        clearTimeout(this.scrollTimer);
      }
    });
  }

}
