import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from '../_services/shared.service';

@Directive({
    selector: '[swipe]'
})
export class SwipeDirectiveDirective {
    @Output() swipeLeft = new EventEmitter();
    @Output() swipeRight = new EventEmitter();

    @Input() elem: any = document;

    constructor(private shared: SharedService) { }

    ngAfterViewInit() {
        this.startOnTouch();
    }

    defaultTouch = { x: 0, y: 0, time: 0 };
    touchStart(touch, event) {
        this.defaultTouch.x = touch.clientX;
        this.defaultTouch.y = touch.clientY;
        this.defaultTouch.time = event.timeStamp;
    }

    touchEnd(touch, event) {
        const deltaX = event.changedTouches[0].clientX - this.defaultTouch.x;
        const startScreenPoint = this.shared.isRtl()? (window.innerWidth - this.defaultTouch.x) : this.defaultTouch.x;
        if (Math.abs(deltaX) > 60) { 
            if (deltaX > 0) {
                this.swipeRight.emit({"startPoint": startScreenPoint});
            } else {
                this.swipeLeft.emit({"startPoint": startScreenPoint});
            }
        }
    }

    touchMove(touch, event) {
        /*if (!this.isScrolling) {
            const deltaX = event.touches[0].clientX - this.defaultTouch.x;
            const deltaY = event.touches[0].clientY - this.defaultTouch.y;
            if (Math.abs(deltaX) > Math.abs(deltaY)) {
                //event.preventDefault(); // Prevent vertical scroll
                this.isScrolling = true; // Horizontal scroll detected, prevent swipe
            }
        }*/
    }

    startOnTouch() {
        const events = [
            'touchstart',
            'touchmove',
            'touchend'
        ];
        const eventStreams = events.map((ev) => Observable.fromEvent(this.elem, ev));
        const allEvents$ = Observable.merge(...eventStreams);
        const subscription = allEvents$.subscribe((event: TouchEvent) => {
            let touch = event["touches"][0] || event["changedTouches"][0];
            if (event.type === 'touchstart') {
                this.touchStart(touch, event);
            }
            else if (event.type === 'touchmove') {
                this.touchMove(touch, event);
            }
            else if (event.type === 'touchend') {
                this.touchEnd(touch, event);
            }
        });
    }
}
