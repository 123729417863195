import { EventEmitter, Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable()
export class LoaderService {
  hideLoader = [
    // '/api/fourms/InitForums',
    '/api/MyPupils/SetPedegogicalSummaryParams',
    '/api/Menu/GetMenuCounters',
    '/api/contacts/SearchContact',
    '/api/messageBox/RecipientsAutoComplete',
    '/api/Menu/GetPreviewUnreadMessages',
    '/api/messageBox/GetMessagesInboxData',
    '/api/messageBox/GetMessagesOutboxData',
    '/api/messageBox/GetMessagesOutbox',
    '/api/messageBox/GetMessagesDraft',
    '/api/messageBox/GetMessagesDeleted',
    '/api/messageBox/GetMessagesInbox',
    '/api/messageBox/GetAllRecipients',
    '/api/dashboard/ShotefSchedualeDataForToday',
    '/api/dashboard/GetHomeWork',
    '/api/dashboard/GetPupilDiciplineEvents',
    '/api/dashboard/GetGrades',
    '/api/shotef/ChangesAndMessagesDataForToday',
    '/api/settings/GetSMSVerifyCode',
    '/api/EducatorTeacher/GetAssesmentBank',
    '/api/Spaces/GetSpacesNews',
    '/api/user/ChangeUserAfterCancel',
    '/api/webtopManagment/GetAllPupils',
    '/api/stream/saveUsersImage',
    '/api/dashboard/SaveShortcuts',
    '/api/general/SaveStatistics',
    '/api/grades/GetAssessmentBank2',
    '/api/userManagment/saveReconnect',
    '/api/general/writeToLog',
    '/api/shotef/SchoolchangesPerGrade',
    '/api/NotificationsUpdates/SetSystemUpdatatesAsRead',
    '/api/userManagment/GetUsersSecond',
    '/api/user/SendOneTimePasswordChangeUser'
  ];
  onLoadingChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  baseUrl = this.getUrl();

  getUrl() {

    switch (window.location.host.toLowerCase()) {
      case 'localhost:4200':
      case '192.168.4.42:4200':
      case '127.0.0.1:4200':
        return 'https://localhost:44389';
        break;
      case 'testclient.smartschool.co.il':
        return 'https://testserver.smartschool.co.il/server';
        break;
      case 'webtop.smartschool.co.il':
        return 'https://webtopserver.smartschool.co.il/server';
        break;
    }
  }
  getUrlImage() {

    switch (window.location.host.toLowerCase()) {
      case 'localhost:4200':
      case '192.168.4.42:4200':
      case '127.0.0.1:4200':
        return 'https://localhost:44389';
        break;
      case 'testclient.smartschool.co.il':
        return 'https://testserver.smartschool.co.il/server';
        break;
      case 'webtop.smartschool.co.il':
        return 'https://webtopserver.smartschool.co.il/serverimages';
        break;
    }
  }
  /**
   * Stores all currently active requests
   */
  private requests: HttpRequest<any>[] = [];

  /**
   * Adds request to the storage and notifies observers
   */
  onStarted(req: HttpRequest<any>): void {
    if (req.url.indexOf(this.baseUrl) === -1) {
      return;
    }
    const tmp = req.url.replace(this.baseUrl, '');
    if (this.hideLoader.includes(tmp)) {
      return;
    }
    this.requests.push(req);
    this.notify();
  }


  /**
   * Removes request from the storage and notifies observers
   */
  onFinished(req: HttpRequest<any>): void {
    try {
      const index = this.requests.indexOf(req);
      if (index !== -1) {
        this.requests.splice(index, 1);
      }
      this.notify();
    } catch (e) {
      console.log('onFinished');
      console.log(e);
    }

  }
  public closeLoader() {
    this.onLoadingChanged.emit(false);
  }

  public openLoader() {
    console.log('openLoader')

    this.onLoadingChanged.emit(true);
  }

  private notify(): void {
    this.onLoadingChanged.emit(this.requests.length !== 0);
  }



}
