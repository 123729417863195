import { environment } from 'src/environments/environment';

import { Component, OnInit, Input, AfterContentChecked, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UserService } from '../../_services/user-service.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css']
})
export class AvatarComponent implements OnInit, AfterContentChecked {
  env: any;
  constructor(
    public user: UserService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.env = environment;
  }

  @Input() svgIcon = false;
  @Input() iconName = '';
  @Input() showIcon = false;
  @Input() showWaitingStrip = true;
  @Input() icon = '';
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() id: string;
  @Input() cssClass = '';
  @Input() bgColor: string;
  @Input() imageUrl: string = '';
  @Input() waitingImageUrl: string = '';
  @Input() showAvatar = true;
  @Output() onIconClick = new EventEmitter();
  @Output() alertNoImage = new EventEmitter<boolean>();

  shortName: string;
  bgStyle: string;
  hideSimpleAvatar: boolean = false;

  ngAfterContentChecked(): void {
    this.getShortName();
    if (this.id) {
      if (this.bgColor !== undefined && this.bgColor !== null && this.bgColor !== '') {
        this.bgStyle = this.bgColor;
      } else {
        this.bgStyle = this.colorHash(this.id).toString();
      }
    }


  }
  setFlag() {
    this.showAvatar = false;
  }

  getShowAvatar() {
    return this.showAvatar;
  }

  noImage(flag) {
    this.alertNoImage.emit(flag);
  }
  update() {
    this.imageUrl += `&date=${(new Date).getTime()}`;
    this.changeDetector.detectChanges();
  }

  iconClick() {
    this.onIconClick.emit();
  }

  getShortName(): void {
    let sn = '';
    if (this.lastName !== undefined && this.lastName !== '' && this.lastName != null) {
      if (this.firstName !== undefined && this.firstName !== '' && this.firstName != null)
        sn += this.lastName.charAt(0);
      else {
        const names = this.lastName.split(" ");
        sn += names.length > 0 ? (names[0].charAt(0) + (names.length > 1 ? names[1].charAt(0) : '')) : '';
      };
    }
    if (this.firstName !== undefined && this.firstName !== '' && this.firstName != null) {
      if (this.lastName !== undefined && this.lastName !== '' && this.lastName != null)
        sn += this.firstName.charAt(0);
      else {
        const names = this.firstName.split(" ");
        sn += names.length > 0 ? (names[0].charAt(0) + (names.length > 1 ? names[1].charAt(0) : '')) : '';
      }
    }
    if (sn === '') { sn = '--'; }
    this.shortName = sn;
  }

  ngOnInit(): void {
    this.getShortName();
    if (this.id) {
      this.bgStyle = this.colorHash(this.id).toString();
    } else {
      this.bgStyle = this.colorHash(this.user.getUser().userId).toString();
    }

  }

  colorHash(inputString) {
    let sum = 0;

    for (const i in inputString) {
      sum += inputString.charCodeAt(i);
    }

    const r: any = ~~(Number('0.' + Math.sin(sum + 1).toString().substr(6)) * 256);
    const g: any = ~~(Number('0.' + Math.sin(sum + 2).toString().substr(6)) * 256);
    const b: any = ~~(Number('0.' + Math.sin(sum + 3).toString().substr(6)) * 256);

    const rgb = 'rgb(' + r + ', ' + g + ', ' + b + ')';

    let hex = '#';

    hex += ('00' + r.toString(16)).substr(-2, 2).toUpperCase();
    hex += ('00' + g.toString(16)).substr(-2, 2).toUpperCase();
    hex += ('00' + b.toString(16)).substr(-2, 2).toUpperCase();

    return {
      r: r
      , g: g
      , b: b
      , rgb: rgb
      , hex: hex
    }.rgb;
  }



}


