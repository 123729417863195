import { BlockSlot } from './../../_models/shotef/EventsTestsBoard';
import { MobileService } from './../../_services/mobile-service.service';
import { SharedService } from 'src/app/_services/shared.service';
import { formattedDate } from './../../_pipes/formattedDate';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Lesson, PupilCardScheduale } from 'src/app/_models/pupilCard/PupilCardScheduale';
import { SchedulaeChanges } from 'src/app/_models/shotef/ChangesAndMessages';
import { PdfExportService } from 'src/app/_services/PdfExportService';
import { ContentCardComponent } from '../pageStructure/content-card/content-card.component';
import { Colors } from 'src/app/_consts/colors';
import { PostService } from 'src/app/_services/postService';
import { take, map, isEmpty } from 'rxjs/operators';
import { baseResult } from 'src/app/_models/baseResult';
import { InitDataSchedule, TeacherGroup } from 'src/app/_models/shotef/EventsTestsBoard';
import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { SortItem } from 'src/app/_models/sortItem';

@Component({
  selector: 'app-scheduale-view',
  templateUrl: './scheduale-view.component.html',
  styleUrls: ['./scheduale-view.component.scss']
})
export class SchedualeViewComponent implements OnInit {

  public listChanged = false;
  private _scheduale: PupilCardScheduale[];
  @Input() editSchedule = false;
  colorIndex = 0;
  @Input() set scheduale(value: PupilCardScheduale[]) {
    if (value != null) {
      this._scheduale = value;


      this._scheduale.forEach(x => {
        x?.hoursData?.forEach(hour => {
          hour.scheduale.forEach(lesson => {
            if (lesson.subject in this.subjectsColors)
              return;
            this.subjectsColors[lesson.subject] = this.colors[this.colorIndex].value;
            this.colorIndex++;
            if (this.colorIndex == this.colors.length)
              this.colorIndex = 0;
          })
        })
      });
    }
  }
  get scheduale() {
    return this._scheduale;
  }
  @Input() todayOnly: boolean = false;
  @Input() showChanges: boolean = false;
  @Input() title: string = '';
  @Input() printTitle = '';
  @Input() color: boolean;
  @Input() viewRooms: boolean = true;
  @Output() setTeacherWithoutPlaning = new EventEmitter<any>()
  @ViewChild('content') content: ElementRef;
  @ViewChild(ContentCardComponent) contentCard: ContentCardComponent;
  rooms: SortItem[];
  teacherGroups: TeacherGroup[];
  blockSlots: BlockSlot[];
  subjectsColors: any = {};
  readonly colors = Colors.colors;
  blockSchedule = false;

  constructor(
    private translate: TranslateService,
    private postSrv: PostService,
    private formatDate: formattedDate,
    private pdfService: PdfExportService,
    public mobileService: MobileService,
    private shared: SharedService
  ) {
  }

  activeDay: number = (new Date().getDay() + 1);

  public getStyles() {
    return {
      'grid-template-columns': `1fr repeat(${this.scheduale.length}, 2fr)`
    };
  }
  printTable(title) {
    this.printTitle = title;
    setTimeout(() => {
      window.print();
      setTimeout(() => {
        this.printTitle = '';
      }, 501);
    }, 10);
  }

  savePdf(selectedItem, selectedType) {
    //this.printTitle = title;
    this.pdfService.SavePDFNew(
      'SchedualeView.pdf',
      `Timetable_Changes&tabIndex=1&selectedItem=${selectedItem}&selectedType=${selectedType}`);

  }
  drop(event: CdkDragDrop<string[]>) {
    console.log(event)
    /* if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    } */
  }

  getShotefChangeContent(item: SchedulaeChanges, hour: number): string {
    var content: string = ""
    if (item.type == "Merge")
      content = this.translate.instant('StudentOf') + " " + item.group + " " + this.translate.instant('MeregeTo') + item.lastName + " " + item.privateName;
    else {
      if (item.type == "fillUp") {
        content += ", " + this.translate.instant("MoveLessonFrom") + " " + item.hourName + " " + this.translate.instant("ForStudentOf") + " " + item.group;
      }
      else {
        if (item.type == "cancel") {
          if (item.original_date != null)
            content += "(" + this.translate.instant("MoveFrom") + this.formatDate.transform(item.original_date) + " " + item.original_hour + ")";
          else content += "(" + item.hour + " " + this.translate.instant('Canceled')
        }
        else if (item.fillUpType == "MoveLesson") {
          if (item.original_hour == hour && item.original_date == item.date) {
            item.definition = this.translate.instant("LessonCanceled");
            item.group = null;
          }
          else {
            item.definition = item.type;
          }
        }
        if (item.lastName?.length > 0)
          content += " " + this.translate.instant("Of") + " " + item.lastName + " " + item.privateName;
        if (item.group != null)
          content += " " + this.translate.instant("ForStudentOf") + " " + item.group;
      }
      if (item.content != null) {
        content = item.content + " " + content;
        if (item.classesList.length > 0) {
          content += ", " + this.translate.instant(item.classesList.length > 1 ? "Classes" : "Class") + ":";
          item.classesList.forEach(elem => {
            this.translate.instant("Grade" + elem.class_Code) + " " + elem.class_Num;
          });
          content += this.translate.instant("Only");
        }
      }
    }
    if (item.room != null)
      content += " ," + this.translate.instant(item.room.indexOf(",") > 0 ? "Rooms" : "Room") + ": " + item.room;
    if (item.note != null && item.hour != null && item.hour === hour)
      content += " (" + this.translate.instant("Remark") + ": " + item.note + ")";
    return item.definition + ' ' + content;
  }
  isSchedualeExists(scheduale) {
    return scheduale.scheduale.length === 0;
  }

  changeActiveDay(day: number): void {
    this.activeDay = day;
  }

  ngOnInit(): void {
    if (this.scheduale == null)
      return;
    while (this.scheduale.find(x => x.dayIndex == this.activeDay) == undefined) {
      this.activeDay++;
      if (this.activeDay > 7)
        this.activeDay = 1;
    }
  }

  getClassesList(classes: string) {
    return classes.split(",").map(x => {
      const classData = x.split("$");
      return `${this.translate.instant('Grade' + classData[0])} ${classData[1]}`;
    }).join(",");
  }

  LoadTeacherGroupsList(selectedItem: string | number, year: number) {
    this.postSrv.postData('api/shotef/GetInitDataSchedule',
      true,
      {
        'id': selectedItem,
        'year': year,
      },
      true
    ).pipe(take(1)).subscribe((result: baseResult<InitDataSchedule>) => {
      if (result.data) {
        this.setTeacherWithoutPlaning.emit(result.data.teacherListLessonPlaning);
        this.teacherGroups = result.data.teacherGroups;
        this.blockSlots = result.data.blockSlots;
        this.teacherGroups = this.shared.distinctArray(this.teacherGroups, ['id', 'smartGroupName'])
        this.teacherGroups.forEach(t => {
          if (!this.shared.isEmpty(t.classes)) {
            t.classes = t.classes.split(', ').slice(1).join(', ');
          }
          if (this.shared.isEmpty(this.subjectsColors[t.subject])) {
            this.subjectsColors[t.subject] =this.colors[this.colorIndex].value;
            this.colorIndex++;
          }
        })
        this.setCounter();
        this.rooms = [];
        this.rooms = result.data.rooms.map(x => { return { 'text': `${x.name} (${x.id})`, value: x.id } })
        this.rooms.unshift({ 'text': `${this.translate.instant('noRoom')}`, value: -1 })
      }
    });
  }

  roomClick($event, dayInGrid, hour) {
    if ($event.value == -1) {
      this.scheduale[dayInGrid].hoursData[hour].scheduale[0].roomID = null
      this.scheduale[dayInGrid].hoursData[hour].scheduale[0].room = '';
    } else {

      this.scheduale[dayInGrid].hoursData[hour].scheduale[0].roomID = $event.value;
      this.scheduale[dayInGrid].hoursData[hour].scheduale[0].room = $event.text.substring(0, $event.text.lastIndexOf('(')).trim();
    }
  }

  isBlocked(dayInGrid, hour) {
    return this.blockSlots?.filter(x => x.day == (dayInGrid + 1) && x.hour == hour).length > 0;
  }

  addBlocked(dayInGrid, hour) {
    this.listChanged =true;

    this.blockSlots.push({ day: dayInGrid + 1, hour: hour });
  }

  removeBlocked(dayInGrid, hour) {
    this.listChanged =true;
    this.blockSlots = this.blockSlots?.filter(x => !(x.day == (dayInGrid + 1) && x.hour == hour));
  }

  onDragResultReleased($event, x, source) {

    this.listChanged = true;
    if (this.shared.isEmpty($event.event.toElement.dayInGrid)) {
      if (this._scheduale[x.day - 1] != undefined)
        this._scheduale[x.day - 1].hoursData[x.hour].scheduale = [];
      this.setCounter();
      return;
    }

    let lesson = new Lesson();
    lesson.day = $event.event.toElement.dayInGrid + 1;
    lesson.classes = x.classes;
    lesson.subject = x.subject;
    lesson.hour = $event.event.toElement.hour;
    lesson.studyGroupID = source == 1 ? x.studyGroupID : x.id;
    lesson.isItemFromScheduale = true;
    if (!this.isFreeSpace(lesson.day, lesson.hour, -1)) return;
    if (source == 1 && this._scheduale[$event.event.toElement.dayInGrid]?.hoursData[$event.event.toElement.hour]?.scheduale.length == 0) {
      this._scheduale[x.day - 1].hoursData[x.hour].scheduale = [];
    }
    if (this._scheduale[$event.event.toElement.dayInGrid]?.hoursData[$event.event.toElement.hour]?.scheduale.length == 0)
      this._scheduale[$event.event.toElement.dayInGrid]?.hoursData[$event.event.toElement.hour]?.scheduale.push(lesson);
    this.setCounter();
  }


  setCounter() {
    this.teacherGroups?.forEach(x => x.counter = this.getCount(x.id))
  }
  getCount(id): number {
    let cnt = 0;
    this.scheduale.forEach(s => {
      s.hoursData.forEach(h => {
        cnt += h.scheduale.filter(hs => hs.studyGroupID == id).length;
        h.isAllowed = h.scheduale.filter(hs => hs.studyGroupID == id).length == 0;

      });
    });
    return cnt;

  }

  isAllowedToDropToPlaceholder = (item: CdkDrag<any>, drop: CdkDropList) => {
    let day = (drop.element.nativeElement as any).dayInGrid;
    let hour = (drop.element.nativeElement as any).hour;
    return this.isFreeSpace(day, hour);
  }

  isFreeSpace(day, hour, x = 0) {
    let tmp = this._scheduale[day + x].hoursData[hour].scheduale.length == 0 && !this.isBlocked(day + x, hour);
    return tmp;
  }


}

