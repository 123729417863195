import { MobileService } from 'src/app/_services/mobile-service.service';
import { take } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, AfterViewChecked, AfterContentChecked, OnDestroy } from '@angular/core';
import { SortItem } from '../../../_models/sortItem';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SortDialogComponent } from '../../dialogs/sort-dialog/sort-dialog.component';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-sort-btn',
  templateUrl: './sort-btn.component.html',
  styleUrls: ['./sort-btn.component.css', '../btn-icon/btn-icon.component.css']
})
export class SortBtnComponent implements OnInit {

  @Input() public title: string;
  @Input() public indexTab = 0;
  @Input() public toolTip: string;
  @Input() public icon: string;
  @Input() public sortOptions: SortItem[];
  @Input() public sortOptions2: SortItem[];
  @Input() public extraAction: string;
  @Input() public setSelected = true;
  @Input() public showFilterActive = false;
  @Input() public iconCss = '';
  @Input() public inputId = '';
  @Input() public disabled = false;
  @Input() public closeAll = true;
  @Input() public showMobileMenu = false;
  @Input() public flatBtn: boolean = false;
  @Input() public selectedItem = null;
  @Input() public matMenuCss = '';
  @ViewChild('menuBtn') menu: MatMenuTrigger;
  @Output() selectedSortOption = new EventEmitter();
  @Output() extraActionClicked = new EventEmitter();

  constructor(
    private bottomSheet: MatBottomSheet,
    public sharedService: SharedService,
    public mobileService: MobileService
  ) {
  }

  setFocus() {
    if (!this.mobileService.isMobileView) {
      this.menu.focus();
    }
  }

  extraActionClick(): void {
    this.extraActionClicked.emit(true);
  }

  sortItemClick(selectedItem: SortItem): void {
    //if (this.setSelected === true && this.sortOptions.some(x => x.selected)) {
    this.sortOptions?.forEach(x => x.selected = false);;//.selected = false;
    //}

    //if (this.setSelected === true && this.sortOptions2 && this.sortOptions2.some(x => x.selected)) {
    this.sortOptions2?.forEach(x => x.selected = false);//.selected = false;
    //}

    if (this.setSelected === true) {
      selectedItem.selected = true;
    }
    this.selectedSortOption.emit(selectedItem);
  }

  openModal() {
    try {
      this.setSelectedItem();
      this.menu.openMenu();
    } catch { }
  }
  setSelectedItem() {
    try {
      if (this.setSelected && this.selectedItem != null) {
        if (this.sortOptions.filter(x => x.value == this.selectedItem).length > 0) {
          this.sortOptions.forEach(x => x.selected = false);
          this.sortOptions.filter(x => x.value == this.selectedItem)[0].selected = true;
        }
      }
    }
    catch { }
  }

  openSortModal(): void {
    this.setSelectedItem();

    if (!this.mobileService.isMobileView || this.showMobileMenu) {
      return;
    }
    (document.activeElement as any).blur()
    try {


      const dialogRef = this.bottomSheet.open(SortDialogComponent, {
        autoFocus: true,
        backdropClass: 'web-hidden',
        panelClass: 'web-hidden',
        data: { options: this.sortOptions, title: this.title, extraAction: this.extraAction, options2: this.sortOptions2, setSelected: this.setSelected },
      });

      setTimeout(() => {
        const tmp = this.sortOptions.filter(x => x.selected);
        if (tmp.length > 0) {
          document.getElementById('btnSort_' + tmp[0].value)?.scrollIntoView({ behavior: 'smooth', block: 'center' });

        }
      }, 200);
      dialogRef.afterDismissed().pipe(take(1)).subscribe((selectedItem: SortItem | boolean) => {
        if (selectedItem != null) {
          if (selectedItem === true) {
            this.extraActionClick();
          } else {
            setTimeout(() => {
              this.sortItemClick(selectedItem as SortItem);
            }, 100);
            if (this.mobileService.isMobileView) {
              if (document.getElementsByClassName('cdk-overlay-backdrop').length > 1) {
                document.getElementsByClassName('cdk-overlay-backdrop')[1].remove()
              }
              if (!this.closeAll) return;
              document.getElementsByClassName('cdk-overlay-container')[0].innerHTML = '<div class=""></div>';
            }
          }
        } else {
          if (this.mobileService.isMobileView) {
            if (document.getElementsByClassName('cdk-overlay-backdrop').length > 1) {
              document.getElementsByClassName('cdk-overlay-backdrop')[1].remove()
            }
            if (!this.closeAll) return;
            document.getElementsByClassName('cdk-overlay-container')[0].innerHTML = '<div class=""></div>';
          }
        }
      });
    }
    catch { }
  }

  ngOnInit(): void {


  }

}
