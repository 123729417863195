import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, OnChanges } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { ChartColors, DoughtChartColor } from '../../../_models/charts/DoughtChartColor';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-dought-chart',
  templateUrl: './dought-chart.component.html',
  styleUrls: ['./dought-chart.component.css']
})
export class DoughtChartComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() public chartLabels: string[];
  @Input() public chartData: number[];
  @Input() public toolTips: any[];
  @Input() public cssClass: string = '';
  @Input() public chartId: string = 'canvas';
  @Input() public simpleStringLabel: boolean = true;
  data: number[];
  chart: any = [];
  finishLoad: boolean = false;

  constructor(
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private shared: SharedService
  ) {
  }

  legendClick(item) {
    this.chart.toggleDataVisibility(item.index);
    this.chart.update();
  }

  ngOnChanges() {
    if (!this.finishLoad)
      return;
    this.chart.data.labels = this.chartLabels;
    this.chart.data.datasets[0].data = this.chartData;
    this.chart.update();
  }

  ngAfterViewInit(): void {
    this.chart = new Chart(
      (document.getElementById(this.chartId) as HTMLCanvasElement).getContext('2d'),
      {
        type: 'doughnut',
        data: {
          labels: this.chartLabels,
          datasets: [{
            data: this.chartData,
            backgroundColor: ChartColors.colors,
            hoverBackgroundColor: ChartColors.hoverColors,
            borderWidth: 0
          }]
        },
        options: {
          responsive: true,
          plugins: {
            tooltip: {
              rtl: this.shared.isRtl(),
              enabled: true,
              bodyAlign: this.shared.isRtl() ? 'right' : 'left',
              usePointStyle: true,
              boxWidth: 10,
              boxHeight: 10,
              boxPadding: 8,
              backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--tooltip-background-color'),
              bodyColor: getComputedStyle(document.documentElement).getPropertyValue('--tooltip-text-color'),
              titleColor: getComputedStyle(document.documentElement).getPropertyValue('--tooltip-text-color'),
              cornerRadius: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--tooltip-radius'), 10),
              titleFont: {
                family: 'boldOpenSans, boldMada, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
              },
              bodyFont: {
                family: 'regularOpenSans, regularMada, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
              },
              callbacks: {
                title: function (context: any) {
                  return '';
                },
                label: function (context: any) {
                  if (context.label instanceof String)
                    return ' ' + (context.label as string)
                  else return context.label;
                }
              }
            },
            legend: {
              display: false
            }
          },
        }
      }
    );
    Chart.overrides['doughnut'].plugins.legend.position = 'bottom';
    this.cdr.detectChanges();
    this.finishLoad = true;
  }

  ngOnInit(): void {
  }
}
