// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-cropper {
  width: 128px !important;
  height: 128px !important;
  position: relative;
  overflow: hidden;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 8px;
}

img {
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: 100%;
}
.breakLine {
  white-space: initial !important;
}

.divider {
  border-bottom: 1px solid rgba(0,0,0,0.12) !important;
}
.adjustments {
  margin: 8px 0;
}
.spaceBetween{
  place-content: center space-between;
}
.gradeFailed {
  color: var(--red);
}
.eventName {
  max-width: 90%;
}

@media screen and (min-width: 960px){
  ::ng-deep .canvas-wrapper canvas{
    max-height: 200px;
    max-width: 200px;
  }
}

.dividerEvalution {
  color: var(--card-secodary-title-color);
  border-color: var(--card-secodary-title-border-color);
}

.avatar-placeholder{
  margin-bottom: 8px;
  width: 128px;
  height: 128px;
}`, "",{"version":3,"sources":["webpack://./src/app/common/dialogs/user-data-dialog/user-data-dialog.component.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,wBAAwB;EACxB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,YAAY;EACZ,WAAW;AACb;AACA;EACE,+BAA+B;AACjC;;AAEA;EACE,oDAAoD;AACtD;AACA;EACE,aAAa;AACf;AACA;EACE,mCAAmC;AACrC;AACA;EACE,iBAAiB;AACnB;AACA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,iBAAiB;IACjB,gBAAgB;EAClB;AACF;;AAEA;EACE,uCAAuC;EACvC,qDAAqD;AACvD;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;AACf","sourcesContent":[".image-cropper {\r\n  width: 128px !important;\r\n  height: 128px !important;\r\n  position: relative;\r\n  overflow: hidden;\r\n  text-align: center;\r\n  border-radius: 50%;\r\n  margin-bottom: 8px;\r\n}\r\n\r\nimg {\r\n  display: inline;\r\n  margin: 0 auto;\r\n  height: 100%;\r\n  width: 100%;\r\n}\r\n.breakLine {\r\n  white-space: initial !important;\r\n}\r\n\r\n.divider {\r\n  border-bottom: 1px solid rgba(0,0,0,0.12) !important;\r\n}\r\n.adjustments {\r\n  margin: 8px 0;\r\n}\r\n.spaceBetween{\r\n  place-content: center space-between;\r\n}\r\n.gradeFailed {\r\n  color: var(--red);\r\n}\r\n.eventName {\r\n  max-width: 90%;\r\n}\r\n\r\n@media screen and (min-width: 960px){\r\n  ::ng-deep .canvas-wrapper canvas{\r\n    max-height: 200px;\r\n    max-width: 200px;\r\n  }\r\n}\r\n\r\n.dividerEvalution {\r\n  color: var(--card-secodary-title-color);\r\n  border-color: var(--card-secodary-title-border-color);\r\n}\r\n\r\n.avatar-placeholder{\r\n  margin-bottom: 8px;\r\n  width: 128px;\r\n  height: 128px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
