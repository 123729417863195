import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class IconsService {

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) { }
  public iconList =  {

      'schadule_room_full': 'assets\\icons\\schadule_room_full.svg',
      'schadule_room_empty': 'assets\\icons\\schadule_room_empty.svg',
      'edit_schedule': 'assets\\icons\\edit_schedule.svg',
      'substitute_teacher': 'assets\\icons\\substitute_teacher.svg',
      'justification': 'assets\\icons\\justification.svg',
      'export_excel_file': 'assets\\icons\\export_excel_file.svg',
      'face_id': 'assets\\icons\\face_id.svg',
      'fingerprint': 'assets\\icons\\fingerprint.svg',
      'virtual_school': 'assets\\icons\\Side_Menu\\virtual_school_moe.svg',
      'send_by_message': 'assets\\icons\\send_by_message.svg',
      'export_file': 'assets\\icons\\export_file.svg',
      'google_calender': 'assets\\icons\\google_calender.svg',
      'import_data': 'assets\\icons\\import_data.svg',
      'scan_list': 'assets\\icons\\scan_list.svg',
      'transparent': 'assets\\icons\\transparent.svg',
      'calc_manot': 'assets\\icons\\calc_manot.svg',
      'edit_detailes': 'assets\\icons\\edit_detailes.svg',
      'color_fill': 'assets\\icons\\color_fill.svg',
      'fill_color_reset': 'assets\\icons\\fill_color_reset.svg',
      'arrow_circle_up': 'assets\\icons\\arrow_circle_up.svg',
      'arrow_circle_down': 'assets\\icons\\arrow_circle_down.svg',
      'arrow_down': 'assets\\icons\\arrow_down.svg',
      'rotate': 'assets\\icons\\rotate.svg',
      'nestedArrow': 'assets\\icons\\nested_arrow_left.svg',
      'userManagment': 'assets\\icons\\Side_Menu\\users_management.svg',
      'Back_Arrow': 'assets\\icons\\Back_Arrow.svg',
      'NextArrow': 'assets\\icons\\NextArrow.svg',
      'Calendar': 'assets\\icons\\Calendar.svg',
      'camera': 'assets\\icons\\camera.svg',
      'photo_camera': 'assets\\icons\\photo_camera.svg',
      'check': 'assets\\icons\\check.svg',
      'close': 'assets\\icons\\close.svg',
      'Sms': 'assets\\icons\\sms.svg',
      'import_from_excel': 'assets\\icons\\import_from_excel.svg',
      'close_white': 'assets\\icons\\close_white.svg',
      'cloud_import': 'assets\\icons\\cloud_import.svg',
      'copy': 'assets\\icons\\copy.svg',
      'delete': 'assets\\icons\\delete.svg',
      'Down_Arrow': 'assets\\icons\\Down_Arrow.svg',
      'edit': 'assets\\icons\\edit.svg',
      'filter': 'assets\\icons\\filter.svg',
      'Google_Classroom': 'assets\\icons\\Google_Classroom.svg',
      'justifications': 'assets\\icons\\justifications.svg',
      'Menu_Hamburger': 'assets\\icons\\Menu_Hamburger.svg',
      'menu_open': 'assets\\icons\\menu_open.svg',
      'minus': 'assets\\icons\\minus.svg',
      'Next_Arrow': 'assets\\icons\\Next_Arrow.svg',
      'Plus': 'assets\\icons\\Plus.svg',
      'school_building': 'assets\\icons\\school_building.svg',
      'search': 'assets\\icons\\search.svg',
      'settings': 'assets\\icons\\settings.svg',
      'sort': 'assets\\icons\\sort.svg',
      'sushi_menu': 'assets\\icons\\sushi_menu.svg',
      'View_Options': 'assets\\icons\\View_Options.svg',
      'Zoom_Cloud_Meetings': 'assets\\icons\\Zoom_Cloud_Meetings.svg',
      'Error_Pages_Icon': 'assets\\icons\\error_pages\\Error_Pages_Icon.svg',
      'eye_view': 'assets\\icons\\file_repository\\eye_view.svg',
      'grid_view': 'assets\\icons\\file_repository\\grid_view.svg',
      'info': 'assets\\icons\\file_repository\\info.svg',
      'list_view': 'assets\\icons\\file_repository\\list_view.svg',
      'more_options': 'assets\\icons\\file_repository\\more_options.svg',
      'accdb': 'assets\\icons\\file_repository\\file_formats_squer\\accdb.svg',
      'avi': 'assets\\icons\\file_repository\\file_formats_squer\\avi.svg',
      'bmp': 'assets\\icons\\file_repository\\file_formats_squer\\bmp.svg',
      'csv': 'assets\\icons\\file_repository\\file_formats_squer\\csv.svg',
      'doc': 'assets\\icons\\file_repository\\file_formats_squer\\doc.svg',
      'docx': 'assets\\icons\\file_repository\\file_formats_squer\\docx.svg',
      'dot': 'assets\\icons\\file_repository\\file_formats_squer\\dot.svg',
      'dotx': 'assets\\icons\\file_repository\\file_formats_squer\\dotx.svg',
      'dwg': 'assets\\icons\\file_repository\\file_formats_squer\\dwg.svg',
      'flac': 'assets\\icons\\file_repository\\file_formats_squer\\flac.svg',
      'flip': 'assets\\icons\\file_repository\\file_formats_squer\\flip.svg',
      'flv': 'assets\\icons\\file_repository\\file_formats_squer\\flv.svg',
      'folder': 'assets\\icons\\file_repository\\file_formats_squer\\folder.svg',
      'ggb': 'assets\\icons\\file_repository\\file_formats_squer\\ggb.svg',
      'gif': 'assets\\icons\\file_repository\\file_formats_squer\\gif.svg',
      'http': 'assets\\icons\\file_repository\\file_formats_squer\\http.svg',
      'ink': 'assets\\icons\\file_repository\\file_formats_squer\\ink.svg',
      'jpeg': 'assets\\icons\\file_repository\\file_formats_squer\\jpeg.svg',
      'jpg': 'assets\\icons\\file_repository\\file_formats_squer\\jpg.svg',
      'link': 'assets\\icons\\file_repository\\file_formats_squer\\link.svg',
      'mkv': 'assets\\icons\\file_repository\\file_formats_squer\\mkv.svg',
      'mov': 'assets\\icons\\file_repository\\file_formats_squer\\mov.svg',
      'mp3': 'assets\\icons\\file_repository\\file_formats_squer\\mp3.svg',
      'mp4': 'assets\\icons\\file_repository\\file_formats_squer\\mp4.svg',
      'mpg': 'assets\\icons\\file_repository\\file_formats_squer\\mpg.svg',
      'msw': 'assets\\icons\\file_repository\\file_formats_squer\\msw.svg',
      'note': 'assets\\icons\\file_repository\\file_formats_squer\\note.svg',
      'ogg': 'assets\\icons\\file_repository\\file_formats_squer\\ogg.svg',
      'pdf': 'assets\\icons\\file_repository\\file_formats_squer\\pdf.svg',
      'pkt': 'assets\\icons\\file_repository\\file_formats_squer\\pkt.svg',
      'png': 'assets\\icons\\file_repository\\file_formats_squer\\png.svg',
      'pps': 'assets\\icons\\file_repository\\file_formats_squer\\pps.svg',
      'ppsx': 'assets\\icons\\file_repository\\file_formats_squer\\ppsx.svg',
      'ppt': 'assets\\icons\\file_repository\\file_formats_squer\\ppt.svg',
      'pptx': 'assets\\icons\\file_repository\\file_formats_squer\\pptx.svg',
      'pub': 'assets\\icons\\file_repository\\file_formats_squer\\pub.svg',
      'rtf': 'assets\\icons\\file_repository\\file_formats_squer\\rtf.svg',
      'sb': 'assets\\icons\\file_repository\\file_formats_squer\\sb.svg',
      'sketchup': 'assets\\icons\\file_repository\\file_formats_squer\\sketchup.svg',
      'swf': 'assets\\icons\\file_repository\\file_formats_squer\\swf.svg',
      'tif': 'assets\\icons\\file_repository\\file_formats_squer\\tif.svg',
      'tiff': 'assets\\icons\\file_repository\\file_formats_squer\\tiff.svg',
      'txt': 'assets\\icons\\file_repository\\file_formats_squer\\txt.svg',
      'wav': 'assets\\icons\\file_repository\\file_formats_squer\\wav.svg',
      'wma': 'assets\\icons\\file_repository\\file_formats_squer\\wma.svg',
      'wmv': 'assets\\icons\\file_repository\\file_formats_squer\\wmv.svg',
      'xls': 'assets\\icons\\file_repository\\file_formats_squer\\xls.svg',
      'xlsx': 'assets\\icons\\file_repository\\file_formats_squer\\xlsx.svg',
      'xps': 'assets\\icons\\file_repository\\file_formats_squer\\xps.svg',
      'swf2': 'assets\\icons\\format_files\\swf2.svg',
      'add_box': 'assets\\icons\\forums\\add_box.svg',
      'add_bubble': 'assets\\icons\\forums\\add_bubble.svg',
      'drag_indicator': 'assets\\icons\\forums\\drag_indicator.svg',
      'forums_icon': 'assets\\icons\\forums\\forums_icon.svg',
      'forum_bubble': 'assets\\icons\\forums\\forum_bubble.svg',
      'notification_off': 'assets\\icons\\forums\\notification_off.svg',
      'notification_on': 'assets\\icons\\forums\\notification_on.svg',
      'pushpin': 'assets\\icons\\forums\\pushpin.svg',
      'qoute': 'assets\\icons\\forums\\qoute.svg',
      'rename': 'assets\\icons\\forums\\rename.svg',
      'share': 'assets\\icons\\forums\\share.svg',
      //'Hide_Password': 'assets\\icons\\login\\Hide_Password.svg',
      'Hide_Password': 'assets\\icons\\login\\visibility_off_2.svg',
      'voice _msg': 'assets\\icons\\login\\voice _msg.svg',
      'code_by_mail': 'assets\\icons\\login\\code_by_mail.svg',
      'Password': 'assets\\icons\\login\\Password.svg',
      'Show_Password': 'assets\\icons\\login\\Show_Password.svg',

      'UserName': 'assets\\icons\\login\\username.svg',
      'User_Name': 'assets\\icons\\login\\User_Name.svg',
      'LoginLogo': 'assets\\icons\\login\\Webtop_icon.svg',
      'icons_managment': 'assets\\icons\\manege_webtop\\icons_managment.svg',
      'statistics': 'assets\\icons\\manege_webtop\\statistics.svg',
      'safe_school': 'assets\\icons\\manege_webtop\\safe_school.svg',
      'Add_File': 'assets\\icons\\messegas\\Add_File.svg',
      'move_to_inbox': 'assets\\icons\\messegas\\move_to_inbox.svg',
      'add_recipients': 'assets\\icons\\messegas\\add_recipients.svg',
      'delete_from_users': 'assets\\icons\\messegas\\delete_from_users.svg',
      'download': 'assets\\icons\\messegas\\download.svg',
      'email': 'assets\\icons\\messegas\\email.svg',
      'file': 'assets\\icons\\messegas\\file.svg',
      'folders': 'assets\\icons\\messegas\\folders.svg',
      'forword': 'assets\\icons\\messegas\\forword.svg',
      'Mailing_groups': 'assets\\icons\\messegas\\Mailing_groups.svg',
      'signature_massage': 'assets\\icons\\messegas\\signature_massage.svg',
      'survey_massage': 'assets\\icons\\messegas\\survey_massage.svg',
      'move_to_folder': 'assets\\icons\\messegas\\move_to_folder.svg',
      'add_new_folder': 'assets\\icons\\messegas\\add_new_folder.svg',
      'parents': 'assets\\icons\\messegas\\parents.svg',
      'parents_in_classes': 'assets\\icons\\messegas\\parents_in_classes.svg',
      'pupils': 'assets\\icons\\messegas\\pupils.svg',
      'pupils_in_classes': 'assets\\icons\\messegas\\pupils_in_classes.svg',
      'read_message': 'assets\\icons\\messegas\\read_message.svg',
      'reply': 'assets\\icons\\messegas\\reply.svg',
      'reply_all': 'assets\\icons\\messegas\\reply_all.svg',
      'signature': 'assets\\icons\\messegas\\signature.svg',
      'study_groups': 'assets\\icons\\messegas\\study_groups.svg',
      'teachers': 'assets\\icons\\messegas\\teachers.svg',
      'timer_schedule': 'assets\\icons\\messegas\\timer_schedule.svg',
      'unresd_message': 'assets\\icons\\messegas\\unresd_message.svg',
      'Cell_Phone': 'assets\\icons\\phone_book\\Cell_Phone.svg',
      'desk_phone': 'assets\\icons\\phone_book\\desk_phone.svg',
      'sms': 'assets\\icons\\phone_book\\sms.svg',
      'whatsapp': 'assets\\icons\\phone_book\\whatsapp.svg',
      'Email_address': 'assets\\icons\\phone_book\\Email_address.svg',
      'Phone': 'assets\\icons\\phone_book\\Phone.svg',
      'Search': 'assets\\icons\\phone_book\\Search.svg',
      '10': 'assets\\icons\\pupil_Card\\10.svg',
      '11': 'assets\\icons\\pupil_Card\\11.svg',
      '12': 'assets\\icons\\pupil_Card\\12.svg',
      '13': 'assets\\icons\\pupil_Card\\13.svg',
      '14': 'assets\\icons\\pupil_Card\\14.svg',
      '32': 'assets\\icons\\pupil_Card\\32.svg',
      '33': 'assets\\icons\\pupil_Card\\33.svg',
      '34': 'assets\\icons\\pupil_Card\\34.svg',
      '38': 'assets\\icons\\pupil_Card\\privet_detailes.svg',
      '39': 'assets\\icons\\pupil_Card\\self_educational_program.svg',
      '4': 'assets\\icons\\pupil_Card\\4.svg',
      '5': 'assets\\icons\\pupil_Card\\5.svg',
      '6': 'assets\\icons\\pupil_Card\\6.svg',
      '7': 'assets\\icons\\pupil_Card\\7.svg',
      '8': 'assets\\icons\\pupil_Card\\8.svg',
      '9': 'assets\\icons\\pupil_Card\\9.svg',
      'add_Justifications_request': 'assets\\icons\\pupil_Card\\add_Justifications_request.svg',
      'circle': 'assets\\icons\\pupil_Card\\circle.svg',
      'SmartScreen': 'assets\\icons\\Side_Menu\\smart_screen.svg',
      'Avartar_Img': 'assets\\icons\\Side_Menu\\Avartar_Img.svg',
      'Avatar_Full': 'assets\\icons\\Side_Menu\\Avatar_Full.svg',
      'Brainpop_ELL': 'assets\\icons\\Side_Menu\\Brainpop_ELL.svg',
      'Brainpop_Israel': 'assets\\icons\\Side_Menu\\Brainpop_Israel.svg',
      'class_space': 'assets\\icons\\Side_Menu\\class_space.svg',
      'coronaForm': 'assets\\icons\\Side_Menu\\coronaForm.svg',
      'coronaSignature': 'assets\\icons\\Side_Menu\\coronaSignature.svg',
      'Dayly_Dashboard': 'assets\\icons\\Side_Menu\\Dayly_Dashboard.svg',
      'Digital_schoolbag': 'assets\\icons\\Side_Menu\\Digital_schoolbag.svg',
      'Digital_schoolbag_HighSchool': 'assets\\icons\\Side_Menu\\dashboard.svg',
      'Disconnect_Exit': 'assets\\icons\\Side_Menu\\Disconnect_Exit.svg',
      'Educator_Noats': 'assets\\icons\\Side_Menu\\Educator_Noats.svg',
      'EduIL': 'assets\\icons\\Side_Menu\\EduIL.svg',
      'disciplineEvents': 'assets\\icons\\Side_Menu\\Events_In_Lesson.svg',
      'Events_In_Lesson': 'assets\\icons\\Side_Menu\\Events_In_Lesson.svg',
      'Events_Outside_class': 'assets\\icons\\Side_Menu\\Events_Outside_class.svg',
      'files_repository': 'assets\\icons\\Side_Menu\\files_repository.svg',
      'Form': 'assets\\icons\\Side_Menu\\Form.svg',
      'forums': 'assets\\icons\\Side_Menu\\forums.svg',
      'Galim': 'assets\\icons\\Side_Menu\\Galim.svg',
      'GoogleClassroom': 'assets\\icons\\Side_Menu\\GoogleClassroom.svg',
      'Google_education_cloud': 'assets\\icons\\Side_Menu\\Google_education_cloud.svg',
      'office_cloud': 'assets\\icons\\Side_Menu\\office_cloud.svg',
      'grades': 'assets\\icons\\Side_Menu\\Grades.svg',
      'anual_grades': 'assets\\icons\\Side_Menu\\anual_grades.svg',
      'greeting_card': 'assets\\icons\\Side_Menu\\greeting_card.svg',
      'help': 'assets\\icons\\Side_Menu\\help.svg',
      'learning_space': 'assets\\icons\\Side_Menu\\learning_space.svg',
      'Lnet': 'assets\\icons\\Side_Menu\\Lnet.svg',
      'Matach_Logo': 'assets\\icons\\Side_Menu\\Matach_Logo.svg',
      'mathApp': 'assets\\icons\\Side_Menu\\mathApp.svg',
      'Messages': 'assets\\icons\\Side_Menu\\Messages.svg',
      'signMessaes': 'assets\\icons\\Side_Menu\\Approvals_and_signatures.svg',
      'MOE_Privet_Lessons': 'assets\\icons\\Side_Menu\\MOE_Privet_Lessons.svg',
      'Nipagesh_Logo': 'assets\\icons\\Side_Menu\\Nipagesh_Logo.svg',
      'notification': 'assets\\icons\\Side_Menu\\notification.svg',
      'notificationUpdates':  'assets\\icons\\Side_Menu\\notification.svg',
      'Phone_Book': 'assets\\icons\\Side_Menu\\Phone_Book.svg',
      'menuePushpin': 'assets\\icons\\Side_Menu\\Menu_Pushpin.svg',
      'School_Website': 'assets\\icons\\Side_Menu\\School_Website.svg',
      'Settings': 'assets\\icons\\Side_Menu\\Settings.svg',
      'Signature': 'assets\\icons\\Side_Menu\\Signature.svg',
      'Student_Card': 'assets\\icons\\Side_Menu\\Student_Card.svg',
      'upload_student_image':'assets\\icons\\upload_student_image.svg',
      'My_Pupils': 'assets\\icons\\Side_Menu\\Student_Card.svg',
      'Support': 'assets\\icons\\Side_Menu\\Support.svg',
      'tenFingers': 'assets\\icons\\Side_Menu\\tenFingers.svg',
      'Timetable_Changes': 'assets\\icons\\Side_Menu\\Timetable_Changes.svg',
      'Timetoknow_Logo': 'assets\\icons\\Side_Menu\\Timetoknow_Logo.svg',
      'trackingNotes': 'assets\\icons\\Side_Menu\\trackingNotes.svg',
      'adjustments': 'assets\\icons\\Side_Menu\\adjustments.svg',
      'User_Guide': 'assets\\icons\\Side_Menu\\User_Guide.svg',
      'webtop_managment': 'assets\\icons\\Side_Menu\\webtop_managment.svg',
      'Weekly_Plan': 'assets\\icons\\Side_Menu\\Weekly_Plan.svg',
      'ZoomUs': 'assets\\icons\\Side_Menu\\ZoomUs.svg',
      'wizdi': 'assets\\icons\\Side_Menu\\wizdi.svg',
      'gingim': 'assets\\icons\\Side_Menu\\gingim.svg',
      'qr_code': 'assets\\icons\\Side_Menu\\qr_code.svg',
      'HealthDeclaration': 'assets\\icons\\Side_Menu\\MOE_health_Declaration.svg',
      'qr_code_scanner': 'assets\\icons\\Side_Menu\\qr_code_scanner.svg',
      '18': 'assets\\icons\\teacher_‏‏pupil_Card\\18.svg',
      '2': 'assets\\icons\\teacher_‏‏pupil_Card\\2.svg',
      '22': 'assets\\icons\\teacher_‏‏pupil_Card\\22.svg',
      '3': 'assets\\icons\\teacher_‏‏pupil_Card\\3.svg',
      'export_pdf': 'assets\\icons\\export_pdf.svg',
      'export_word': 'assets\\icons\\export_word.svg',
      'reset_paswword': 'assets\\icons\\reset_paswword.svg',
      'DateCalender':'assets\\icons\\settings\\calender.svg',
      'EmailNotification':'assets\\icons\\settings\\email_notifications.svg',
      'ApplicationLanguage':'assets\\icons\\settings\\language.svg',
      'ViewMode':'assets\\icons\\settings\\theme_mode.svg',
      'PushNotification':'assets\\icons\\settings\\notifications.svg',
      'passwordSettings':'assets\\icons\\settings\\password_icon.svg',
      'TextSize':'assets\\icons\\settings\\text_size.svg',
      'personalDetails':'assets\\icons\\settings\\user_detailes.svg',
      'userNameSettings':'assets\\icons\\settings\\user_name_icon.svg',
      'zoom_in_text':'assets\\icons\\settings\\zoom_in_text.svg',
      'zoom_out_text':'assets\\icons\\settings\\zoom_out_text.svg',
      'accessibility':'assets\\icons\\settings\\accessibility.svg',
      'Low_saturation':'assets\\icons\\settings\\Low_saturation.svg',
      'Monochrome':'assets\\icons\\settings\\Monochrome.svg',
      'ReadableFont':'assets\\icons\\settings\\text_format.svg',
      'LightMode':'assets\\icons\\settings\\light_mode.svg',
      'DarkMode':'assets\\icons\\settings\\dark_mode.svg',
      'MarkTitle':'assets\\icons\\settings\\mark_title.svg',
      'MarkLinks':'assets\\icons\\settings\\underline_link.svg',
      'Bright_contrast':'assets\\icons\\settings\\Bright_contrast.svg',
      'Dark_contrast':'assets\\icons\\settings\\Dark_contrast.svg',
      'High_saturation':'assets\\icons\\settings\\High_saturation.svg',
      'corona_health_statement': 'assets\\icons\\corona\\corona_health_statement.svg',
      'flag_arabic': 'assets\\icons\\corona\\flag_arabic.svg',
      'flag_ethiopia': 'assets\\icons\\corona\\flag_ethiopia.svg',
      'flag_france': 'assets\\icons\\corona\\flag_france.svg',
      'flag_hebrew': 'assets\\icons\\corona\\flag_hebrew.svg',
      'flag_russia': 'assets\\icons\\corona\\flag_russia.svg',
      'flag_us': 'assets\\icons\\corona\\flag_us.svg',
      'flag_ti': 'assets\\icons\\corona\\flag_eritrea.svg',
      'fillCheckbox': 'assets\\icons\\fillCheckbox.svg',
      'externalSites': 'assets\\icons\\Side_Menu\\externalSites.svg',
      'externalSitesLink': 'assets\\icons\\externalSitesLink.svg',
      'spaces_0':  'assets\\icons\\Side_Menu\\notification.svg',
      'spaces_1':  'assets\\icons\\spaces\\content_page.svg',
      'spaces_2': 'assets\\icons\\Side_Menu\\forums.svg',
      'spaces_3': 'assets\\icons\\spaces\\birthday.svg',
      'spaces_5':'assets\\icons\\Side_Menu\\Weekly_Plan.svg',
      'spaces_6': 'assets\\icons\\pupil_Card\\11.svg',
      'spaces_7': 'assets\\icons\\spaces\\task.svg',
      'spaces_8': 'assets\\icons\\file_repository\\file_formats_squer\\http_link.svg',
      'spaces_9': 'assets\\icons\\Side_Menu\\Phone_Book.svg',
      'spaces_10': 'assets\\icons\\spaces\\RSS.svg',
      'spaces_11':'assets\\icons\\pupil_Card\\10.svg',
      'spaces_12': 'assets\\icons\\Side_Menu\\files_repository.svg',
      'spaces_13':'assets\\icons\\Side_Menu\\Form.svg',
      'noImage': 'assets\\icons\\no_image.svg',
      'contentSetting': 'assets\\icons\\content_settings.svg',
      'spaceTask': 'assets\\icons\\spaces\\task.svg',
      'spaceContentpage': 'assets\\icons\\spaces\\content_page.svg',
      'spaceSubject': 'assets\\icons\\spaces\\content_pages_subject.svg',
      'spaceCategory': 'assets\\icons\\spaces\\content_pages_category.svg',
      'reports': 'assets\\icons\\reports.svg',
      'language': 'assets\\icons\\language.svg',
      'cancelEvent': 'assets\\icons\\cancelEvent.svg',
      'mettingsScheduale': 'assets\\icons\\Meeting_diary.svg',
      'copyFromDB': 'assets\\icons\\copyFromDB.svg',
      'cookies': 'assets\\icons\\cookies.svg',
      'new_meeting': 'assets\\icons\\Meeting_diary.svg',
      'uploadFile': 'assets\\icons\\upload_file.svg',
      'justificationApproved': 'assets\\icons\\justification_approved.svg',
      'openInNew': 'assets\\icons\\open_in_new.svg',
      'visibilitySettings': 'assets\\icons\\visibility_settings.svg',
      'pupil_without_image': 'assets\\icons\\pupil_without_image.svg',
      'percent_calc': 'assets\\icons\\percent_calc.svg',
      'sum_calc': 'assets\\icons\\sum_calc.svg',
      'grades1': 'assets\\icons\\pupil_Card\\grades1.svg',
      'grades2': 'assets\\icons\\pupil_Card\\grades2.svg',
      /* 'grades3': 'assets\\icons\\pupil_Card\\grades3.svg', */
      'grades3': 'assets\\icons\\pupil_Card\\anual_grades.svg',
      'grades4': 'assets\\icons\\pupil_Card\\grades4.svg',
      'grades5': 'assets\\icons\\pupil_Card\\grades5.svg',
      'literalEvaluations': 'assets\\icons\\Side_Menu\\LiteralEvaluations.svg',
      'MyStudents2': 'assets\\icons\\pupil_Card\\privet_detailes.svg',
      'MyStudents4': 'assets\\icons\\pupil_Card\\11.svg',
      'MyStudents22': 'assets\\icons\\pupil_Card\\10.svg',
      'MyStudents3': 'assets\\icons\\pupil_Card\\4.svg',
      'MyStudents5': 'assets\\icons\\pupil_Card\\6.svg',
      'MyStudents6': 'assets\\icons\\pupil_Card\\7.svg',
      'MyStudents7': 'assets\\icons\\pupil_Card\\8.svg',
      'MyStudents8': 'assets\\icons\\pupil_Card\\32.svg',
      'MyStudents9': 'assets\\icons\\pupil_Card\\9.svg',
      'MyStudents10': 'assets\\icons\\pupil_Card\\12.svg',
      'MyStudents11': 'assets\\icons\\pupil_Card\\13.svg',
      'MyStudents12': 'assets\\icons\\pupil_Card\\5.svg',
      'MyStudents13': 'assets\\icons\\pupil_Card\\14.svg',
      'MyStudents14': 'assets\\icons\\pupil_Card\\33.svg',
      'MyStudents18': 'assets\\icons\\pupil_Card\\student_privet_files.svg',
      'MyStudents21': 'assets\\icons\\pupil_Card\\student_groupes.svg',
      'MyStudents25': 'assets\\icons\\pupil_Card\\self_educational_program.svg',
      'MyStudents23': 'assets\\icons\\pupil_Card\\education_programs.svg',
      'MyStudents0': 'assets\\icons\\pupil_Card\\pedgogical_sumery.svg',
      'Import_grade_older_group': 'assets\\icons\\Import_grade_older_group.svg',
      'BehaviorGrade': 'assets\\icons\\BehaviorGrade.svg',
      'StudentshipGrade': 'assets\\icons\\studentship_grade.svg',

      'thumb_up': 'assets\\icons\\thumb_up.svg',
      'thumb_down': 'assets\\icons\\thumb_down.svg',
      'history': 'assets\\icons\\history.svg',
      'goal': 'assets\\icons\\goal.svg',
      'add_column': 'assets\\icons\\add_column.svg',
      'mekuvanim-lnet': 'assets\\icons\\mekuvanim-lnet.svg',
      'hamkpetza': 'assets\\icons\\hamkpetza.svg',
      'ShortCut_Weekly_Plan': 'assets\\icons\\shortcuts\\Weekly_plan.svg',
      'ShortCut_grades': 'assets\\icons\\shortcuts\\Grades.svg',
      'ShortCut_webtop_managment': 'assets\\icons\\shortcuts\\Webtop_managment.svg',
      'ShortCut_userManagment': 'assets\\icons\\shortcuts\\User_managment.svg',
      'ShortCut_Messages': 'assets\\icons\\shortcuts\\Messages.svg',
      'ShortCut_reports': 'assets\\icons\\shortcuts\\Reports.svg',
      'ShortCut_Support': 'assets\\icons\\shortcuts\\Support.svg',
      'ShortCut_help': 'assets\\icons\\shortcuts\\help.svg',
      'ShortCut_SmartScreen': 'assets\\icons\\shortcuts\\Smart_screen.svg',
      'ShortCut_Sms': 'assets\\icons\\shortcuts\\SMS_masseges.svg',
      'ShortCut_qr_code_scanner': 'assets\\icons\\shortcuts\\qr_code_scanner.svg',
      'ShortCut_qr_code': 'assets\\icons\\shortcuts\\qr_code.svg',
      'ShortCut_externalSites': 'assets\\icons\\shortcuts\\externalSites.svg',
      'ShortCut_mettingsScheduale': 'assets\\icons\\shortcuts\\Mettings scheduale.svg',
      'ShortCut_Form': 'assets\\icons\\shortcuts\\Form.svg',
      'ShortCut_School_Website': 'assets\\icons\\shortcuts\\School_Website.svg',
      'ShortCut_files_repository': 'assets\\icons\\shortcuts\\Repository_files.svg',
      'ShortCut_forums': 'assets\\icons\\shortcuts\\forums.svg',
      'ShortCut_learning_space': 'assets\\icons\\shortcuts\\Spaces.svg',
      'ShortCut_Phone_Book': 'assets\\icons\\shortcuts\\Phone_Book.svg',
      'ShortCut_Timetable_Changes': 'assets\\icons\\shortcuts\\Timetable_changes.svg',
      'ShortCut_My_Pupils': 'assets\\icons\\shortcuts\\Student_card.svg',
      'ShortCut_Events_Outside_class': 'assets\\icons\\shortcuts\\Events_Outside_class.svg',
      'ShortCut_disciplineEvents': 'assets\\icons\\shortcuts\\Events_In_Lesson.svg',
      'ShortCut_trackingNotes': 'assets\\icons\\shortcuts\\Tracking_notes.svg',
      'ShortCut_Educator_Noats': 'assets\\icons\\shortcuts\\Educator_Noats.svg',
      'ShortCut_adjustments': 'assets\\icons\\shortcuts\\adjustments.svg',
      'ShortCut_notification': 'assets\\icons\\shortcuts\\Notifications.svg',
      'ShortCut_signMessaes': 'assets\\icons\\shortcuts\\Sign_messaes.svg',
      'ShortCut_Student_Card': 'assets\\icons\\shortcuts\\Student_card.svg',
      'ShortCut_justification': 'assets\\icons\\shortcuts\\Justifications.svg',
      'ShortCut_periodGrades_sc': 'assets\\icons\\shortcuts\\Periodic_grades.svg',
      'ShortCut_grades/2': 'assets\\icons\\shortcuts\\Periodic_grades.svg',
      'ShortCut_Matriculation_exam_accommodations_sc': 'assets\\icons\\shortcuts\\Matriculation_exam_accommodations.svg',
      'ShortCut_YearlyGrades_sc': 'assets\\icons\\shortcuts\\Annual_grades.svg',
      'ShortCut_grades/3': 'assets\\icons\\shortcuts\\Annual_grades.svg',
      'ShortCut_grades_sc': 'assets\\icons\\shortcuts\\Grades.svg',
      'ShortCut_grades/1': 'assets\\icons\\shortcuts\\Grades.svg',
      'ShortCut_evaluationComponent_sc': 'assets\\icons\\shortcuts\\Assessment_components.svg',
      'ShortCut_grades/5': 'assets\\icons\\shortcuts\\Assessment_components.svg',
      'collapse_all': 'assets\\icons\\collapse_all.svg',
      'expand_all': 'assets\\icons\\expand_all.svg',
      'navigate_before': 'assets\\icons\\navigate_before.svg',
      'navigate_next': 'assets\\icons\\navigate_next.svg',
      'toast_info':'assets\\icons\\toast_info.svg',
      'toast_succcess':'assets\\icons\\toast_success.svg',
      'toast_warning':'assets\\icons\\toast_warning.svg',
      'select_all': 'assets\\icons\\select_all.svg',
      'deselect_all': 'assets\\icons\\deselect_all.svg',
      'check_box': 'assets\\icons\\check_box.svg',
      'description': 'assets\\icons\\meetings\\meeting_description.svg',
      'location': 'assets\\icons\\meetings\\meeting_location.svg',
      'repetition': 'assets\\icons\\meetings\\meeting_repetition.svg',
      'removeParticipant': 'assets\\icons\\meetings\\remove_participants.svg',
      'eventsReply': 'assets\\icons\\events_outside_of_class_replays.svg'  ,
      'clock': 'assets\\icons\\meetings\\meeting_time.svg',
      'checkingParticipants': 'assets\\icons\\meetings\\meeting_checking_participants.svg'
  };
  LoadAllIcons()  {
    for (const key in this.iconList) {
      const value = this.iconList[key];
      this.iconRegistry.addSvgIcon(
        key, this.sanitizer.bypassSecurityTrustResourceUrl(value));
  }

  }

}
