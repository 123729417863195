import { Component, OnInit, Output, EventEmitter, Inject, AfterViewInit } from '@angular/core';
import { SortItem } from '../../../_models/sortItem';
import { TranslateService } from '@ngx-translate/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-sort-dialog',
  templateUrl: './sort-dialog.component.html',
  styleUrls: ['./sort-dialog.component.css']
})
export class SortDialogComponent implements OnInit , AfterViewInit {

  @Output() sortBy = new EventEmitter();
  selectedItem: SortItem;
  constructor(
    private translate: TranslateService,
    private dialogRef: MatBottomSheetRef,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { options: SortItem[], title: string, extraAction?: string,options2: SortItem[], setSelected: boolean}
  ) {
  }
  ngAfterViewInit(): void {
    setTimeout(() => {

      const tmp = this.data.options.filter(x => x.selected);

      if (tmp.length > 0) {
        this.selectedItem = tmp[0];
          document.getElementById('id_' + this.selectedItem.value).scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }, 200);
    }

  onExtraAction(): void {
    this.dialogRef.dismiss(true);
  }

  onSelectedItem(item: SortItem): void {
    this.dialogRef.dismiss(item);
  }

  ngOnInit() {

  }

}
