import { Component, Input, Output, EventEmitter } from '@angular/core'
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SortDialogComponent } from '../../dialogs/sort-dialog/sort-dialog.component';
import { SortItem } from 'src/app/_models/sortItem';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-fab-icon',
  templateUrl: './fab-icon.component.html',
  styleUrls: ['./fab-icon.component.css']
})
export class FabIconComponent {
  @Input() icon: string;
  @Input() isMini: boolean = false;
  @Input() sortOptions: SortItem[];
  @Input() openDDL: boolean = false;
  @Input() bottomModalTitle: string;
  @Input() extraDDLAction: string;
  @Input() showFilterActive: boolean = false;
  @Output() enterKeyPressed = new EventEmitter()
  @Output() clickEvent = new EventEmitter()
  @Output() extraActionClicked = new EventEmitter();

  constructor(
    private bottomSheet: MatBottomSheet,
    //public sharedService: SharedService,
  ) {
  }

  openBottomSheetModal() {
    var dialogRef = this.bottomSheet.open(SortDialogComponent, {
      autoFocus: true,
      backdropClass: 'web-hidden',
      panelClass: 'web-hidden',
      data: { options: this.sortOptions, title: this.bottomModalTitle, extraAction: this.extraDDLAction, setSelected: false }
    });
    dialogRef.afterDismissed().pipe(take(1)).subscribe((selectedItem: SortItem | boolean) => {
      if (selectedItem != null) {
        if (selectedItem === true) {
          this.extraActionClicked.emit(true);
          return true;
        }
        if (this.sortOptions.some(x => x.selected)) {
          this.sortOptions.find(x => x.selected).selected = false;
        }
        (selectedItem as SortItem).selected = true;
        this.clickEvent.emit(selectedItem as SortItem);
      }
    });
  }
}
