export class MenuItem{
  id: number;
  isRemoveable: boolean;
  title: string;
  moduleFile: string;
  newWindow: boolean;
  isMobileEnabled: boolean;
  isMobileIcon: string;
  viewOrder: number;
  hasSubItems: boolean;
  subItems: MenuItemSubItem[];
  isExpnad: boolean;
}

export interface MenuItemSubItem{
  url: string;
  title: string;
}

export class MenuDate{
  menuItems: MenuItem[];
  lastLoginDate: Date;
  counters: Countres;
  hasWebtop: boolean;
}

export class Countres{
  unreadMessages: number;
  unreadNotifications: number;
}
