import { PushService } from './../_services/pushService';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../_services/shared.service';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.css']
})
export class PushNotificationComponent {
  constructor(
    private shared: SharedService,
    private router: Router,
    private pushService: PushService
  ) {

  }

  ngOnInit(): void {

    let url = this.shared.getParamValueFromQuery('stateUrl');
    let allParams = this.shared.getAllParamsFromQuery();

    if (!this.shared.isEmpty(url)) {
      if (url.split('/').length > 1 && this.shared.getPreviousUrl().split('/').length > 1 &&
        url.split('/')[1] == this.shared.getPreviousUrl().split('/')[1]) {
        this.router.navigate(['dashboard']);
      } else {
        this.pushService.redirectPyParams(url,false,allParams)
      }
    } else {
      this.router.navigate(['/account/login']);
    }
  }


}
