
import { SortItem } from './../../../_models/sortItem';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.css']
})
export class DragAndDropComponent implements OnInit {

  @Input() items: SortItem[] = [];
  @Input() emptyItemsMessage = '';
  @Output() sortChange = new EventEmitter<SortItem[]>();
  index1 = -1;
  constructor() { }

  ngOnInit(): void {
  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.sortChange.emit(this.items);
  }
   trackByFn(index: number, item: String) {
    return index;
  }

  changeFocus(x) {
    if (x < 0 ) {
      x = this.items.length - 1;
    } else if (x > this.items.length - 1) {
      x = 0;
    }
    document.getElementById('row' + x).focus();
  }

  newSort(i: number){
    if (this.index1 === -1) {
      this.index1 = i;
      document.getElementById('row' + i)?.classList?.add('bg');
      return;
    }
    document.getElementById('row'+ this.index1)?.classList?.remove('bg');
    moveItemInArray(this.items, this.index1, i);
    this.sortChange.emit(this.items);
    this.index1 = -1;

  }
}
