import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-biometric-confirm',
  templateUrl: './biometric-confirm.component.html',
  styleUrls: ['./biometric-confirm.component.css']
})
export class BiometricConfirmComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<BiometricConfirmComponent>,
  ) { }
  ngOnInit(): void {
    setTimeout(() => {
      document.getElementById("confirmBtn").focus();

    }, 200);

  }
  checkScrollHeight(): (this: HTMLElement, ev: Event) => any {
    var agreementTextElement = document.getElementById("textBlock");
    if ((agreementTextElement.scrollTop + agreementTextElement.offsetHeight) >= agreementTextElement.scrollHeight){
      console.log('false')
        //document.getElementsByName("button")[0].disabled = false;
    }
    return null;
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  onConfirmClick() {
    this.dialogRef.close(true);
  }
}
