import { SharedService } from './../../../_services/shared.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-inline-text',
  templateUrl: './inline-text.component.html',
  styleUrls: ['./inline-text.component.scss']
})
export class InlineTextComponent implements OnInit, OnChanges {


  @Input() value: string;
  originalValue: string;
  @Input() placeHolder: string;
  @Input() disabled: boolean;
  @Input() inputType = 'text';
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() direction: string = "rtl";
  @Input() disableAutoLtr = false;
  @Input() cssClass = '';
  @Input() maxLength: number = 100;
  @Input() minValue = '';
  @Input() inputMode = 'text';
  @Input() maxValue = '';
  @Input() cssClassMatFormField = '';

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() valueChangeBlur: EventEmitter<any> = new EventEmitter<any>();
  currentValue ='';
  constructor(
    private shared: SharedService
  ) { }

  ngOnChanges(changes: SimpleChanges) {

    this.control.setValue(this.value);
    this.originalValue = this.control.value;

    if (this.disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
    if (!this.shared.isEmpty(this.minValue)) {
      this.control.addValidators(Validators.min(parseInt(this.minValue)))
    }
    if (!this.shared.isEmpty(this.maxValue)) {
      this.control.addValidators(Validators.max(parseInt(this.maxValue)))
    }
  }

  dataChanged($event) {
    if (this.disableAutoLtr) {
      return;
    }
    if (this.inputType === 'numeric' && this.control.value !== null && this.control !== undefined) {
      this.control.setValue(this.control?.value?.toString().replace(/[^0-9.]*/g, ''));
    }
    if (this.maxLength !== undefined && this.control.value?.length > this.maxLength) {
      this.control.setValue(this.control.value.slice(0, this.maxLength));

    }

    this.valueChange.emit(this.control.value);
    if (!this.shared.isUnicode(this.control.value)) {
      this.direction = 'ltr';
    } else {
      this.direction = 'rtl';

    }

  }

  dataChangedBlur($event) {
    if(this.control.disabled) {
      return;
    }
    this.valueChangeBlur.emit({
      value: this.control.value,
      classList: Array.from(($event.target as HTMLElement).classList).join(' '),
      textChanged: this.originalValue != this.control.value
    });

    this.originalValue = this.control.value;
  }

  ngOnInit() {
  }

}
