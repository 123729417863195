import { FilesService } from './files.service';
import { RsaService } from 'src/app/_services/Rsa.service';
import { CookiesService } from 'src/app/_services/cookies-service.service';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/_services/user-service.service';
import { environment } from 'src/environments/environment';
import { MobileService } from './mobile-service.service';
import { PostService } from './postService';
import { take } from 'rxjs/operators';
import { ElementRef, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Params, QueryParamsHandling, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs-compat';
import { HDate } from '@hebcal/core';
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image-more';
import { MessageBoxDialogComponent } from '../common/dialogs/message-box-dialog/message-box-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import { EventAttributes, createEvent, createEvents } from 'ics';
import { AngularDeviceInformationService } from 'angular-device-information';


@Injectable({
  providedIn: 'root'
})
export class SharedService {


  isBirthDayToday(birthDay: Date, useHebrewDate: boolean) {
    if (useHebrewDate) {
      const birthDate = new HDate(new Date(birthDay));
      const today = new HDate(new Date());
      return birthDate['day'] == today['day'] && birthDate['month'] == today['month']
    } else {
      const birthDate = new Date(birthDay);
      const today = new Date();
      return birthDate.getMonth() == today.getMonth() && birthDate.getDate() == today.getDate()
    }
  }
  fixJson(jsonData: string): string | Blob {
    return jsonData.replace(/\\n/g, "\\n")
      .replaceAll(/\\'/g, "\\'")
      .replaceAll(/\\"/g, "\\\"")

      .replaceAll(/\\"/g, '\\"')
      .replaceAll(/\\&/g, "\\&")
      .replaceAll(/\\r/g, "\\r")
      .replaceAll(/\\t/g, "\\t")
      .replaceAll(/\\b/g, "\\b")
      .replaceAll(/`/g, "\\`")
      .replaceAll(/\\f/g, "\\f");
  }

  private icons = new BehaviorSubject<any[]>([]);
  keys = [
    ['a', 'ש'],
    ['b', 'נ'],
    ['c', 'ב'],
    ['d', 'ג'],
    ['e', 'ק'],
    ['f', 'כ'],
    ['g', 'ע'],
    ['h', 'י'],
    ['i', 'ן'],
    ['j', 'ח'],
    ['k', 'ל'],
    ['l', 'ך'],
    ['m', 'צ'],
    ['n', 'מ'],
    ['o', 'ם'],
    ['p', 'פ'],
    ['q', '/'],
    ['r', 'ר'],
    ['s', 'ד'],
    ['t', 'א'],
    ['u', 'ו'],
    ['v', 'ה'],
    ['w', '\''],
    ['x', 'ס'],
    ['y', 'ט'],
    ['z', 'ז'],
    [',', 'ת']
  ];
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  private urlStack: string[] = [];
  deviceData: { [id: string]: any } = {};
  constructor(
    private router: Router,
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private postSrv: PostService,
    private mobileSrv: MobileService,
    private user: UserService,
    private datePipe: DatePipe,
    private cookiesService: CookiesService,
    private rsa: RsaService,
    private filesService: FilesService,
    private matDialog: MatDialog,
    private deviceInformationService: AngularDeviceInformationService

  ) {
    this.initDeviceData();
    this.getUrl();
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.urlStack.push(this.previousUrl);
        this.currentUrl = event.url;
      };
    });
  }

  serializeQueryParams(queryParams: any): string {
    const keys = Object.keys(queryParams);
    if (!keys.length) return '';
    return '?' + keys.map(key => `${key}=${queryParams[key]}`).join('&');
  }

  initDeviceData() {
    this.deviceData['isMobile'] = this.deviceInformationService.isMobile();                // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    this.deviceData['isTablet'] = this.deviceInformationService.isTablet();                // returns if the device is a tablet (tablet iPad etc)
    this.deviceData['isDesktop'] = this.deviceInformationService.isDesktop();              // returns if the app is running on a Desktop browser.
    this.deviceData['getDeviceType'] = this.deviceInformationService.getDeviceType();      // returns if the app is running on a Desktop browser.
    this.deviceData['os'] = this.deviceInformationService.getDeviceInfo().os;              // returns os name like Windows/Andtoid/iOS/Linux/Mac OS X etc
    this.deviceData['osVersion'] = this.deviceInformationService.getDeviceInfo().osVersion;// returns os version like 10/8.1/7 ...etc
    this.deviceData['browser'] = this.deviceInformationService.getDeviceInfo().browser;    // returns browser name like chrome/firefox ...etc
    this.deviceData['browserVersion'] = this.deviceInformationService.getDeviceInfo().browserVersion;  // returns browser version as number
    this.deviceData['browserMajorVersion'] = this.deviceInformationService.getDeviceInfo().browserMajorVersion;  // returns full browser version as number
    this.deviceData['screen_resolution'] = this.deviceInformationService.getDeviceInfo().screen_resolution;      // returns screnn size like 1390x860/640x800 ...etc
    this.deviceData['cookies'] = this.deviceInformationService.getDeviceInfo().cookies; // returns cookies enabled or no
    this.deviceData['userAgent'] = this.deviceInformationService.getDeviceInfo().userAgent;

  }

  getUrlStack(pos = -1) {
    if (pos == -1)
      return this.urlStack;
    if (this.urlStack.length - 1 > pos)
      return this.urlStack[pos];
    return this.urlStack[0];
  }

  setUrlStack(value: string[]) {
    this.urlStack = value;
  }

  removeLastStackItem() {
    this.urlStack.pop();
  }

  getLastStackItem() {
    if (this.urlStack.length > 0)
      return this.urlStack[this.urlStack.length - 1];
    return '';
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  saveStatistics(moduleName: string, functionName: string) {
    if (this.user.getUser() === null) {
      return;
    }
    this.postSrv.postData('api/general/SaveStatistics',
      true,
      {
        'isMobile': this.deviceData['isMobile'],
        'moduleName': moduleName,
        'functionName': functionName
      }
    ).pipe(take(1)).subscribe();
  }


  getDateNumber(dateToConvert: any) {
    return new Date(dateToConvert).getDay() + 1;
  }
  isEmpty(value: any): boolean {
    return value === undefined || value === null || value === '' || value === 'null';
  }

  validateEmail(email) {
    if (this.isEmpty(email)) {
      return false;
    }
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateCellphone(data:string) {
    if (this.isEmpty(data) || data.length < 10) {
      return false;
    }
    const re = /^05[0-9][\s.-]?[0-9]{7}$/;
    return re.test(String(data).toLowerCase());
  }

  validateUrl(url) {
    if (this.isEmpty(url)) {
      return false;
    }
    const pattern = new RegExp('^((http|https):\/\/)(www.)?([a-z0-9א-ת]{2,256})(\.[a-z]*)((\.[a-z]*)?)*((\/[a-zא-ת0-9@:%.\-_\\+~#?&//=?]*))*$', 'i'); // fragment locator
    return !!pattern.test(url.toLowerCase());
  }

  getClassName(classCode, classNum, title: string = ''): string {
    if (!this.isEmpty(title)) return title;
    if (this.isEmpty(classCode) && this.isEmpty(classNum)) { return ''; }
    let className;
    if (classNum == 255) {
      className = this.translate.instant('instructorClass');
    } else if (classCode === 0 || classCode === 33) {
      className = this.translate.instant('Grade0');
    } else if (classCode > 0 && classCode < 15) {
      className = this.translate.instant('Grade' + classCode);
    } else { className = this.translate.instant('UnknownGrade') + ' ' + this.translate.instant('Code') + '-' + classCode; }

    if (classNum !== undefined && classNum !== null && classNum < 250) {
      return className + ' ' + classNum;
    } else { return className; }
  }

  getClassTitle(classNum, isParent: boolean): string {
    if (isParent) {
      if (classNum == 255) {
        return this.translate.instant('ParentsOf');
      }
      return this.translate.instant((classNum === undefined || classNum === null) ? 'parentsOfGrade' : 'parentsOfClass');
    }
    if (classNum == 255) {
      return this.translate.instant('StudentOf');
    }
    return this.translate.instant((classNum === undefined || classNum === null) ? 'studentsOfGrade' : 'studentsOfClass');
  }

  setMsgId(msgid: string) {
    this.addQueryParams({ 'msgId': msgid }, '');
    //this.cookiesService.setItem('msgId', msgid);
  }
  getMsgId() {
    return this.getParamValueFromQuery('msgId');
    //return this.cookiesService.getItem('msgId');
  }

  addQueryParams(param: any, queryParamHandling: QueryParamsHandling = 'merge', skipLocationChange: boolean = false) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: param,
      queryParamsHandling: queryParamHandling,
      skipLocationChange: skipLocationChange
    });
  }

  getParamValueFromQuery(paramName: string): any {
    const tmp = this.activatedRoute.snapshot.queryParams[paramName];
    if (!this.isEmpty(tmp)) {
      return tmp;
    }
    return null;
  }

  getAllParamsFromQuery(): Params {
    return this.activatedRoute.snapshot.queryParams;
  }

  isNumber(val) {
    return !isNaN(Number(val));
  }

  isUnicode(str: string) {
    const letters = [];
    if (this.isEmpty(str)) {
      return true;
    }
    for (let i = 0; i <= str.length; i++) {
      letters[i] = str.substring((i - 1), i);
      if (letters[i].charCodeAt() > 255) { return true; }
    }
    return false;
  }

  isHebrewDate(): boolean {
    return (localStorage.getItem('datepicker') == null && this.translate.currentLang === 'he') || (localStorage.getItem('datepicker') === 'hebrew');
  }

  monthDiff(d1, d2) {
    d1 = new Date(d1);
    d2 = new Date(d2);
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  compare(a: any, b: any): number {
    if (a > b) { return +1; }
    if (a < b) { return -1; }
    return 0;
  }

  randomString(length, chars) {
    if (this.isEmpty(chars)) {
      chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    let result = '';
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  addDays(futureDate: Date, days: number): Date {
    futureDate = new Date(futureDate);
    futureDate.setDate(futureDate.getDate() + days);
    return futureDate;
  }

  getDisplayDate(date: Date, format = null) {

    return this.datePipe.transform(date, format ?? this.translate.instant('dateFormat'));
  }

  getDisplayDateAndHour(date: Date) {
    return this.datePipe.transform(date, this.translate.instant('dateAndHourFormat'));
  }

  scrollToTop() {
    window.scroll(0, 0);
  }

  getLang() {
    const lang = localStorage.getItem('language');
    if (lang === undefined || lang === null) {
      return environment.defaultLanguage;
    }
    return lang;
  }

  isRtl() {
    let lang = localStorage.getItem('language');
    if (lang === undefined || lang === null) {
      lang = environment.defaultLanguage;
    }
    if (lang == 'he' || lang == 'ar') {
      return true;
    }
    return false;
  }
  replcaeLanguage(str) {
    const pos = this.contains_heb(str) ? 0 : 1;
    let newStr = '';
    str?.split('').forEach(element => {
      try {
        newStr += (this.keys.filter(x => x[0] === element || x[1] === element)[0])[pos];
      } catch {
        newStr += element;
      }

    });
    return newStr;
  }

  contains_heb(str) {
    return (/[\u0590-\u05FF]/).test(str);
  }

  hasMoreThanAscii(str: string): boolean {
    return !/^[\u0000-\u007f]*$/.test(str)
  }

  hasDuplicates(myArray, fieldName) {
    for (var i = 0; i < myArray.length; i++) {
      for (var j = 0; j < myArray.length; j++) {
        if (i != j) {
          if (myArray[i][fieldName] == myArray[j][fieldName]) {
            return true; // means there are duplicate values
          }
        }
      }
    }
    return false; // means there are no duplicate values.
  }


  uniqBy(arr, key) {
    const uniqueIds = [];

    const unique = arr.filter(element => {
      const isDuplicate = uniqueIds.includes(element[key]);

      if (!isDuplicate) {
        uniqueIds.push(element[key]);

        return true;
      }

      return false;
    });

    return unique;

  }
  getSelectedChildren(childrens: any[], idProp = 'id', getDefaultifExists = false) {
    let storageChildren = localStorage.getItem('selectedChildren');
    let studentUrl = this.getParamValueFromQuery('student');
    if (!this.isEmpty(studentUrl)) {

      studentUrl = studentUrl.replaceAll(' ', '+')
      storageChildren = studentUrl;
      localStorage.setItem('selectedChildren', studentUrl);
    }
    if (!this.isEmpty(storageChildren)) {
      if (childrens?.filter(x => x[idProp] == storageChildren).length > 0) {
        return childrens.filter(x => x[idProp] == storageChildren)[0];
      } else {
        if (getDefaultifExists) {
          if (childrens?.length > 0) {
            return childrens[0];
          }
        }
        localStorage.removeItem('selectedChildren');
        return 'childrenError';
      }
    }
    if (childrens?.length > 0) {
      return childrens[0];
    }
  }


  getSelectedChildrenValue() {
    return localStorage.getItem('selectedChildren');
  }

  setSelectedChildren(id) {
    localStorage.setItem('selectedChildren', id);
  }

  //instiCodeImages = environment.studtentImage.split(',')

  getImageParam(studentTz, token, instCode, isWaiting: any = false, userType: number | string = 0) {

    /* if(this.instiCodeImages.filter(x=> x == instCode || x =='*').length == 0)  {
      return '';
    } */
    if (this.isEmpty(token)) {
      return '';
    }

    let tmpStudentTz = '';

    try {
      this.rsa.decryptStringFromServer(studentTz);
    } catch { }

    if (this.isEmpty(tmpStudentTz)) {
      tmpStudentTz = studentTz;
    }
    if (isWaiting || isWaiting == 1) {
      const url = `${this.apiUrlImage}/api/stream/GetImage?id=${tmpStudentTz}&instiCode=${instCode}&token=${token}&isWaitinig=1&usertype=${userType}`;
      return url;
    }
    else {
      const url = `${this.apiUrlImage}/api/stream/GetImage?id=${tmpStudentTz}&instiCode=${instCode}&token=${token}&usertype=${userType}&ts=${this.imgTs}`;
      return url;

    }

  }
  imgTs = (new Date()).getTime();
  apiUrl = '';
  apiUrlImage = '';
  renewImgTs() {
    this.imgTs = (new Date()).getTime();
  }
  getUrl() {
    switch (window.location.host.toLowerCase()) {
      case '192.168.4.42:4200':
      case 'localhost:4200':
      case '127.0.0.1:4200':
        this.apiUrlImage = this.apiUrl = 'https://localhost:44389';
        break;
      case 'testclient.smartschool.co.il':
        this.apiUrl = 'https://testserver.smartschool.co.il/server';
        this.apiUrlImage = 'https://testserver.smartschool.co.il/serverImages';
        break;
      case 'webtop.smartschool.co.il':
        this.apiUrl = 'https://webtopserver.smartschool.co.il/server';
        this.apiUrlImage = 'https://webtopserver.smartschool.co.il/serverImages';
        break;
    }
  }

  isProduction() {
    switch (window.location.host.toLowerCase()) {
      case '192.168.4.42:4200':
      case '127.0.0.1:4200':
      case 'localhost:4200':
        return false;
      case 'testclient.smartschool.co.il':
        return false;
      case 'webtop.smartschool.co.il':
        return true;
    }
  }




  /**
 * Returns distinct of array by parameters.
 *

 * @param arr - Array of objects
 * @param keyProps - parameters to disticit [p1,p2]
 * @returns distinct of array by parameters.
 *
 *
 */
  distinctArray(arr, keyProps): any[] {
    if (arr == undefined) {
      return arr;
    }
    const kvArray = arr.map(entry => {
      const key = keyProps.map(k => entry[k]).join('|');
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }

  openAllowFile() {
    const dialogRef = this.matDialog.open(MessageBoxDialogComponent, {
      disableClose: true,
      data: {
        messages: [environment.AllowedFileTypes.split(',').join(', ')],
        confirmBtn: this.translate.instant('OKBtn'), header: this.translate.instant('AllowedFileType')
      },
      autoFocus: true
    });

  }
  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "iOS";
    }

    return "unknown";
  }

  /*
  start: [2023, 12, 12, 12, 15],
   'Subject':`${x.title} (${x.eventTypeName})` ,
          'Start Date':scope.this.share.getDisplayDate(new Date(x.date),'MM/dd/yyyy'),
          'Start Time':`${hours[x.startTime].fromTime}`,
          'End Date': scope.this.share.getDisplayDate(new Date(x.endDate),'MM/dd/yyyy'),
          'End Time':`${hours[x.endTime].toTime}`,
          'All Day Event': false,
          'Description':x.description,
          'Location':'',
          'Private':false
  */




  async exportFileCalendar(filename: string, rows: object[], exportToGoogle: boolean) {

    let arr = [];
    rows.forEach(x => {
      let startDate = new Date(x['Start Date']);
      let startTime = (x['Start Time']);

      if (startTime != undefined && startTime != null && startTime != ':') {
        startDate.setHours(parseInt(startTime.toString().split(':')[0]));
        startDate.setMinutes(parseInt(startTime.toString().split(':')[1]));
      }
      let endDate = new Date(x['End Date']);
      let endTime = (x['End Time']);

      if (endDate != undefined && endDate != null && endTime != undefined && endTime != null && endTime != ':') {
        endDate.setHours(parseInt(endTime.toString().split(':')[0]));
        endDate.setMinutes(parseInt(endTime.toString().split(':')[1]));
      }
      const event =
      {
        uid: x['Uid'],
        title: x['Subject'],
        start: [startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate(), startDate.getHours(), startDate.getMinutes()],
        end: endDate == null ? null : [endDate.getFullYear(), endDate.getMonth() + 1, endDate.getDate(), endDate.getHours(), endDate.getMinutes()],
        description: exportToGoogle ? x['Html Content'] : '',
        htmlContent: exportToGoogle ? '' : x['Html Content'],
        location: x['Location'] ?? ''
      }

      arr.push(event)

    });



    const file = await new Promise((resolve, reject) => {
      createEvents((arr as EventAttributes[]), (error, value) => {
        if (error) {
          reject(error)
        }

        resolve(new File([value], filename, { type: 'text/calendar' }))
      })
    })
    const url = URL.createObjectURL(file as Blob);

    // trying to assign the file URL to a window could cause cross-site
    // issues so this is a workaround using HTML5
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    URL.revokeObjectURL(url);


  }

  isAndroid(): boolean {
    return /android/i.test(navigator.userAgent.toLowerCase())
  }

  isIos(): boolean {
    return /ipad|iphone|ipod/.test(navigator.userAgent.toLowerCase())
  }


  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }


  addFabScrollSpaceOnSubMenuModule() {
    const spacer = document.getElementsByClassName('fab-extra-scroll-space')[0];
    spacer?.classList.remove("hide");
    const container = document.getElementsByTagName('router-outlet')[1];
    if (container) {
      const clone = spacer.cloneNode(true);
      container.parentNode.appendChild(clone);
      spacer.parentNode.removeChild(spacer);
    }
  }




}
