// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lottie-container {
    max-height: calc(100% - 200px);
    max-width: 750px;
}

.space-around {
    justify-content: center;
    align-items: center;
    align-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/no-data/no-data.component.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,qBAAqB;AACzB","sourcesContent":[".lottie-container {\r\n    max-height: calc(100% - 200px);\r\n    max-width: 750px;\r\n}\r\n\r\n.space-around {\r\n    justify-content: center;\r\n    align-items: center;\r\n    align-content: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
