import { Subscription } from 'rxjs/Subscription';
import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, Input, OnChanges, SimpleChanges, ViewEncapsulation, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {  MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable } from 'rxjs-compat/Observable';
import { Subject } from 'rxjs-compat/Subject';
import { startWith, debounceTime, switchMap, take } from 'rxjs/operators';

import { PostService } from 'src/app/_services/postService';
import { baseResult } from 'src/app/_models/baseResult';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})

export class SearchComponent implements OnInit, OnChanges, OnDestroy {


  searchValue: string;
  searchIcon = 'Search';
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger }) autoComplete: MatAutocompleteTrigger;
  @ViewChild('searchInput', { read: ElementRef })

  private searchInput: ElementRef;

  interactedWithSearch = false;

  @Output() searchEvent = new EventEmitter<{ data?: any, action: 'SEARCH' | 'CLEAR' |  'SEARCH CLICK' | 'CLOSE' | 'KEYUP' | 'KEYDOWN' }>();

  @Input() public url: string;
  @Input() public params: any = {};
  @Input() public emptyResult: any ;
  @Input() public ResultType: any;
  @Input() filteredArray: Observable<any[]>;
  @Input() parentSubject: Subject<any>;
  data: any;
  myControl: UntypedFormControl = new UntypedFormControl();

  subscription: Subscription;

  constructor(private postSrv: PostService) { }
  ngOnInit(): void {
    this.filteredArray = (this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap(value => this.search())
      ) as unknown as Observable<any[]>);
      this.subscription = this.parentSubject?.subscribe(event => {
       this.data  = event;
    });
  }

  setFocus() {
    this.searchInput.nativeElement.focus();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  clear() {
    this.myControl.setValue('');
  }
  ngOnChanges(changes: SimpleChanges) {

  }

  onSearchChange(searchValue: string): void {
    this.searchValue = searchValue;
  }
  closePanel(value: any) {
    this.autoComplete.writeValue(value);
    this.autoComplete.closePanel();
    this.closeSearch();
  }
  closeResults() {
    this.autoComplete.closePanel();
  }
  filterbyTextQuery () {
    /* setTimeout(() => {
      this.myControl.setValue(this.searchValue);
    }, 1); */
    this.filterbySearchQuery(this.searchValue);
  }
  filterbySearchQuery(searchValue: string) {

    this.searchEvent.emit({ data: searchValue, action: 'SEARCH CLICK' });
  }

  toggleSearch() {
    this.data = null;
    const searchContainer = document.getElementById('search-container');
    this.toggleClass(searchContainer, 'open');
    this.toggleClass(searchContainer, 'searchClose');
    this.searchIcon = this.hasClass(searchContainer, 'open') ? 'close' : 'Search';

    if (!this.hasClass(searchContainer, 'open') && this.interactedWithSearch) {
      this.searchEvent.emit({ action: 'CLEAR' });
      this.interactedWithSearch = false;
    } else {
      this.setFocus();
    }

    this.searchInput.nativeElement.value = '';
  }

  closeSearch() {
    this.data = null;
    const searchContainer = document.getElementById('search-container');
    searchContainer.classList.remove('open');
    this.searchIcon = this.hasClass(searchContainer, 'open') ? 'close' : 'Search';

    //if (!this.hasClass(searchContainer, 'open') && this.interactedWithSearch) {
      this.searchEvent.emit({ action: 'CLEAR' });
      this.interactedWithSearch = true;
   // }
    this.searchInput.nativeElement.value = '';
  }

  private toggleClass(elem, className) {
    this.hasClass(elem, className) ? elem.classList.remove(className) : elem.classList.add(className);
  }

  public hasClass(elem, className): boolean {
    return elem.classList.contains(className);
  }

   async search() {
    const searchTerm = this.searchInput.nativeElement.value;
    if (this.url === undefined) {
      this.searchEvent.emit({ data: searchTerm, action: 'SEARCH' });
    }
    else this.filter(searchTerm);
    this.interactedWithSearch = true;
  }

   filter(value: string) {
    const newList = [];
     if ((!value || value === undefined || value === '' || value === null
     )) {
       return newList;
     }
     const filterValue = {'SearchQuery': value.toLowerCase()};
     //const result = await this.postSrv.post<any>(this.url, true, Object.assign(this.params, filterValue));

     this.postSrv.postData(this.url, true, Object.assign(this.params, filterValue)
    ).pipe(take(1)).subscribe((result: baseResult<any>) => {
      if (result.data === undefined || result.data === null || (result.data as []).length === 0) {
        newList.push(this.emptyResult);
        return newList;
      }
      this.searchEvent.emit({ data:  result.data, action: 'SEARCH' });
       //return result.data;
    });



   /*  if (result.data === undefined || result.data === null || (result.data as []).length === 0) {
      newList.push(this.emptyResult);
      return newList;
    }
     return result.data; */
   }

   keypress(keyClick: string) {
    this.searchEvent.emit({ data: '', action : (keyClick === 'KEYUP' ? 'KEYUP' : 'KEYDOWN')  });
   }
   enterP() {

   }

}
