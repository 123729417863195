import { join, sep } from 'path';
import { SharedService } from './../../../_services/shared.service';
import { RsaService } from 'src/app/_services/Rsa.service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { baseResult } from 'src/app/_models/baseResult';
import { StudentData } from 'src/app/_models/user';
import { PostService } from 'src/app/_services/postService';
import { PreviousEvent } from 'src/app/_models/DisciplineEvents/disciplineEvents';
import { Adjustment } from 'src/app/_models/DisciplineEvents/DisciplineEventsReport';
import { StudyGroupEventGrades } from 'src/app/_models/grades/studentGrades/studentGradesClass';
import { UserService } from 'src/app/_services/user-service.service';
import { Subject } from 'rxjs';
import { DrawerComponent } from '../../UIControls/drawer/drawer.component';
import { SafeHtmlPipe } from 'src/app/_pipes/safeHtml';

@Component({
  selector: 'app-user-data-dialog',
  templateUrl: './user-data-dialog.component.html',
  styleUrls: ['./user-data-dialog.component.css']
})
export class UserDataDialogComponent implements OnInit {
  studentData: StudentData;
  previousEvents: PreviousEvent[];
  teachers: string;
  openDrawerFlag = false;
  studentId: string;
  options: string[];
  imageSrc: string;
  adjustments: Adjustment[];
  chartData: number[] = [];
  eventsTypes: string[] = [];
  studyGroupEventGrades: StudyGroupEventGrades[] = [];
  eventsSummary: any[];
  yearlySummary: any[];
  destroy = new Subject();
  year: number;
  behaviorGrade: any[];
  studentshipGrade: any[];
  loadStudentData: boolean = false;
  @ViewChild(DrawerComponent) drawer: DrawerComponent;

  @Output() closeUserData = new EventEmitter();
  constructor(
    private postSrv: PostService,
    private rsa: RsaService,
    public sharedService: SharedService,
    private user: UserService,
    private safeHtml: SafeHtmlPipe,

  ) {

    this.user.getSelectedStudyYear().pipe().takeUntil(this.destroy).subscribe(year => {
      this.year = year;
    });
  }

  ngOnInit(): void {

  }



  openDrawer(studentId: string, options: string[], extraData: any = null, dateFilter: Date = null, hourFilter: number = null, behaviourGrade: any = null, studentshipGrade: any = null) {
    this.eventsTypes = [];
    this.chartData = [];
    this.studentId = studentId;
    this.options = options;
    options.forEach(o => {
      switch (o) {
        case 'basicData':
          this.loadBasicData();
          break;
        case 'previousEvents':
        case 'previousEventsOnlyChart':
          let arr = [];
          this.previousEvents = (extraData.previousEvents as PreviousEvent[]).filter(p => p.pupilId === studentId);

          const totalEvents = (extraData.previousEvents as PreviousEvent[])?.filter(p => p.pupilId === studentId).length;
          (extraData.previousEvents as PreviousEvent[])?.filter(p => p.pupilId === studentId).forEach(x => {
            if (!arr.includes(x.descValue)) {
              arr.push(x.descValue);
            }
          });
          let tmpArr: any[] = [];
          arr.forEach(x => {
            const cnt = (extraData.previousEvents as PreviousEvent[])?.filter(p => p.pupilId === studentId && p.descValue === x).length
            //this.chartData.push(cnt);
            let data = `${x} | ${cnt} (${(cnt * 100 / totalEvents).toFixed(2)}%)`;
            //this.eventsTypes.push(data);
            tmpArr.push({ x: x, cnt: cnt, data: data })

          });
          tmpArr = tmpArr.sort((a, b) => a.cnt > b.cnt ? -1 : 1);
          tmpArr.forEach(x=> {
            this.chartData.push(x.cnt);
            this.eventsTypes.push(x.data);
          })
          console.log(tmpArr);

          if (dateFilter != null && hourFilter != null) {
            this.previousEvents = this.previousEvents.filter(p => new Date(p.sDate) < dateFilter ||
              (new Date(p.sDate).getTime() == dateFilter.getTime() && (p.sHour) < hourFilter));
          }
          else if (dateFilter != null) {
            this.previousEvents = this.previousEvents.filter(p => new Date(p.sDate) < dateFilter);
          }
          this.previousEvents = this.previousEvents.slice(0, 10);
          arr = [];
          (extraData.previousEvents as PreviousEvent[])?.filter(p => p.pupilId === studentId).forEach(x => {
            if (!arr.includes(x.descValue)) {
              arr.push(x.descValue);
            }
          });

          break;
        case 'adjustments':
          this.adjustments = (extraData.adjustments as Adjustment[])?.filter(p => p.pupilId === studentId
            && (!this.sharedService.isEmpty(p.descValue) || !this.sharedService.isEmpty(p.comment)));
          break;
        case 'studyGroupEventGrades':
          this.studyGroupEventGrades = extraData.studyGroupEventGrades;
          break;
        case 'eventsSummary':
          this.eventsSummary = extraData.eventsSummary;
          break;
        case 'yearlySummary':
          extraData.yearlySummary = extraData.yearlySummary.sort((a, b) => (a.divisionName > b.divisionName) ? 1 : -1);
          let tmp = this.sharedService.distinctArray(extraData.yearlySummary, ['divisionName']);
          this.yearlySummary = [];
          tmp.forEach(div => {
            if (this.yearlySummary.filter(ys => ys.divisionName == div.divisionName).length == 0) {
              let division: any = {};
              division.divisionName = div.divisionName;
              division.grades = extraData.yearlySummary.filter(grade => grade.divisionName = div.divisionName)
              if (division.grades.length > 0) { this.yearlySummary.push(division); }
            }
          });

          break;
        default:
          break;
      }
    });
    this.behaviorGrade = behaviourGrade;
    this.studentshipGrade = studentshipGrade;
    this.openDrawerFlag = true;
    this.drawer.setFocus();
  }

  closeDrawer() {
    this.openDrawerFlag = false;
    this.closeUserData.emit();
  }

  showDiv(divId: string): boolean {
    return this.options?.filter(o => o === divId).length > 0;
  }

  loadBasicData() {
    this.loadStudentData = true;
    this.studentData = null;
    this.imageSrc = '';
    this.teachers = '';
    this.postSrv.postData<StudentData>('api/user/GetBasicData',
      true, {
      id: this.studentId,
      param1: this.year.toString()
    })
      .pipe(take(1))
      .subscribe((result: baseResult<StudentData>) => {
        if (result.data) {
          this.studentData = result.data;
          this.studentData.studentTz = this.rsa.decryptStringFromServer(this.studentData.studentTz);
          this.imageSrc = `https://www.smartschool.co.il/applications/pupilPhoto/viewer.aspx?id=${this.studentData.studentTz}&s=${this.studentData.institutionCode}&token=${this.studentData.token}`;
          this.teachers = this.studentData.teachers.map(x => x.family_name + ' ' + x.private_name).join();
          this.loadStudentData = false;
        }
      });
  }
  getSplit(data: string, seperator) {
    const res = this.decodeHtml(data)?.toString()?.split(seperator).filter(x => !this.sharedService.isEmpty(x));
    return res;
  }

  decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  getTotal(data) {
    if (this.sharedService.isEmpty(data)) {
      return '';
    }
    let arr: [] = data?.split(',');
    let total = 0;
    let totalPer = 0;
    arr.forEach(x => {
      let tmp = (x as any).split(':')[1];
      let numbers = tmp.match(/\d+/g);
      total += parseFloat(numbers[0]);
      totalPer += parseFloat(numbers[1]);
    });
    return `${total} (${totalPer}%)`;

  }


}
