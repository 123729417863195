import { SharedService } from './../../_services/shared.service';
import { UntypedFormControl } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { take } from 'rxjs/operators';
import { baseResult } from 'src/app/_models/baseResult';
import { PostService } from 'src/app/_services/postService';
import { SnackBarComponent } from 'src/app/common/snack-bar/snack-bar.component';

@Component({
  selector: 'app-first-password-dialog',
  templateUrl: './first-password-dialog.component.html',
  styleUrls: ['./first-password-dialog.component.css']
})
export class FirstPasswordDialogComponent implements OnInit {

  textBox: UntypedFormControl = new UntypedFormControl();
  constructor(
        private dialogRef: MatDialogRef<FirstPasswordDialogComponent>,
        private postSrv: PostService,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private shared:SharedService,
         @Inject(MAT_DIALOG_DATA) public data: { schoolName: number}
  ) { }

  ngOnInit(): void {
  }
  onCancelClick() {
    this.dialogRef.close(false);

  }

  onConfirmClick() {
    if(this.shared.isEmpty(this.textBox.value)) {
      this.snackBar.openFromComponent(SnackBarComponent, {
        duration: 3000,
        data: { message: this.translate.instant('AskForPassword'), type: 'warning' }
      });
      return;
    }

    this.postSrv.postData('api/user/ValidateNewPassword',
    true,
    {
      id: this.textBox.value
    }
  ).pipe(take(1)).subscribe((result: baseResult<boolean>) => {
    if (result.data) {
      this.dialogRef.close(true);
    } else {
      this.snackBar.openFromComponent(SnackBarComponent, {
        duration: 3000,
        data: { message: this.translate.instant('WrongPassword'), type: 'warning' }
      });

    }
  });



  }

}
