
import { SharedService } from './../../../_services/shared.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MobileService } from '../../../_services/mobile-service.service';

@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.css']
})
export class FormDialogComponent implements OnInit {

  @Input() title: string;
  @Input() buttonText: string;
  @Input() titleColor: string;
  @Input() cssClass = '';
  @Input() closeIcon = 'close';
  @Input() rightIcon = '';
  @Input() Hidebuttons = false;
  @Input() showBigFooter = false;
  @Input() disabledButton = true;
  @Input() button2Text = '';
  @Input() heightClass = '';
  @Input() showFooter: boolean = true;
  @Input() transformOriginClass: string;

  @Output() closeDialog = new EventEmitter();
  @Output() submitFunction = new EventEmitter();
  @Output() submit2Function = new EventEmitter();

  constructor(
    private dialogRef: MatDialogRef<FormDialogComponent>,
    private shared: SharedService,
    private mobile: MobileService

  ) {
      this.dialogRef.addPanelClass('edit-modal');

  }

  onSubmit(): void {

    this.submitFunction.emit(null);
  }
  onButton2Click(): void {
    this.submit2Function.emit(null);
  }

  ngOnInit(): void {
    if (!this.shared.isEmpty(this.heightClass) && !this.mobile.isMobileView ) {
      this.dialogRef.addPanelClass(this.heightClass);
    }
  }
  closedialogEvent() {
    if (this.closeDialog.observers.length === 0) {
      this.dialogRef.close(false);
    } else {
      this.closeDialog.emit();
    }
  }

}
